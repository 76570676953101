import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { renderLabel, TIMEZONE, tooltipDateFormat } from "../index";

class CustomComponent extends Component {
    render = () => {
        const { options } = this.props;

        // Init chart data
        const { series, groupBy } = options;

        // First line
        const seriesFirst = series.length > 0 ? series[0] : {};
        const dataFirst = seriesFirst.data ?? [];
        const nameFirst = seriesFirst.name ?? '';
        const typeFirst = seriesFirst.type ?? '';
        const unitFirst = seriesFirst.unit ?? '';

        return (
            <HighchartsReact
                containerProps={{ className: "highcharts-react " }}
                highcharts={Highcharts}
                options={{
                    "chart": {
                        "type": "scatter",
                        "zoomType": "xy"
                    },
                    "title": {
                        "text": "",
                    },
                    "xAxis": [
                        {
                            "type": "datetime",
                            "labels": renderLabel(groupBy),
                        }
                    ],
                    "yAxis": [
                        // First line
                        {
                            "min": 0,
                            "labels": {
                                "format": `{value} ${unitFirst}`,
                                "style": {
                                    "color": "#666666"
                                }
                            },
                            "title": {
                                "text": "",
                            },
                        },
                    ],
                    "tooltip": {
                        "xDateFormat": tooltipDateFormat(groupBy, TIMEZONE),
                        "shared": true
                    },
                    "legend": {},
                    "series": [
                        {
                            "marker": {
                                "enabled": true
                            },
                            "states": {
                                "hover": {
                                    "enabled": true,
                                }
                            },
                            "type": typeFirst,
                            "name": nameFirst,
                            "data": dataFirst,
                        }
                    ],
                    "plotOptions": {
                        "series": {
                            "animation": false,
                            "maxPointWidth": 100
                        },
                    }
                }}
            />
        )
    }
}

export default CustomComponent;