import React, { Component } from 'react';
import { Table } from "antd";
import { renderDataTable } from "../index";

class CustomComponent extends Component {
    render = () => {
        const { options, loading } = this.props;

        // Init chart data
        const { series, groupBy } = options;

        let dataRender = renderDataTable(series, groupBy);
        let { unitSeries, data } = dataRender;
        return (
            <Table
                size="small"
                columns={[
                    {
                        ellipsis: true,
                        align: "center",
                        title: "Date",
                        dataIndex: "time",
                        key: "time",
                        width: 160,
                    },
                    {
                        ellipsis: true,
                        align: "center",
                        title: `Specific Yield (${unitSeries[0]})`,
                        dataIndex: "0",
                        key: "0",
                    },
                    {
                        ellipsis: true,
                        align: "center",
                        title: `Budget Specific Yield (${unitSeries[1]})`,
                        dataIndex: "1",
                        key: "1",
                    }
                ]}
                dataSource={data}
                pagination={false}
                loading={loading}
                scroll={{ y: 500 }}
            >
            </Table>
        )
    }
}

export default CustomComponent;