import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export const reducer = (state = initialState, action) => {
    let { payload, type } = action;
    switch (type) {
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        case CONSTANTS.PORTFOLIO_DETAIL:
            payload = payload ?? {}
            let detail = payload.data ?? {}
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    errors: detail.errors ?? [],
                    data: detail,
                    id: detail.id,
                    isFound: !!detail.id
                },
            };
        case CONSTANTS.PORTFOLIO_DETAIL_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                },
            };


        case CONSTANTS.PORTFOLIO_UPDATE:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    errors: payload.errors,
                    data: payload.data,
                }
            }
        case CONSTANTS.PORTFOLIO_UPDATE_LOADING:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: true,
                }
            }
        default:
            return state;
    }
}