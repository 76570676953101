// Initial state is the place you define all initial values for the Redux store of the feature.

const initialState = {
    detail: {
        loading: false,
        errors : {},
        data   : {},
        id     : null,
        isFound: true,
    },

    meta: {
        actualProductionIrradiation: {
            loading: false,
            options: {
                groupBy: "",
                series : [],
            },
        },

        gaugesMonitoring: {
            loading               : false,
            lastTime              : "-",
            currentMonthProduction: 0,
            currentPower          : 0,
            dataType              : 0,
            installedCapacity     : 0,
            reactivePower         : 0,
            todayProduction       : 0,
            totalPowerFactor      : 0,
            totalProduction       : 0,
        },

        stringMonitoring: {
            loading: false,
            options: {
                groupBy   : "",
                series    : [],
                xAxis     : {},
                yAxis     : {},
                unixLegend: "",
            }
        },

        IVCurve: {
            loading: false,
            options: {
                groupBy: "",
                series : [],
            },
        },

        inverterMonitoring: {
            loading   : false,
            total     : 0,
            totalPower: 0,
            unit      : '',
            lastTime  : '',
            inverters : [],
        },

        actualPrAndBudgetPr: {
            loading: false,
            options: {
                groupBy: "",
                series : [],
            },
        },

        specificYield: {
            loading: false,
            options: {
                groupBy: "",
                series : [
                    {
                        unit: "",
                        type: "",
                        data: [],
                    }, {
                        unit: "",
                        type: "",
                        data: [],
                    }
                ],
            },
        },

        dailyIODiagram: {
            loading: false,
            options: {
                groupBy: "",
                series : [],
            },
        },

        gaugesPerformance: {
            loading                    : false,
            lastTime                   : "-",
            performanceRatioMonthToDate: 0,
            performanceRatioToday      : 0,
            performanceRatioYearToDate : 0,
            performanceRatioYesterday  : 0,
            specificYieldMonthToDate   : 0,
            specificYieldToday         : 0,
            specificYieldYearToDate    : 0,
            specificYieldYesterday     : 0,
        },

        performanceRatioTracking: {
            loading: false,
            options: {
                groupBy: "",
                series : [],
            },
        },

        actualProductionVsBudgetProduction: {
            loading: false,
            options: {
                groupBy: "",
                series : [],
            },
        },

        carbonOffset: {
            loading: false,
            options: {
                groupBy: "",
                series : [],
            },
        },

        inverterPerformance: {
            loading  : false,
            total    : 0,
            inverters: [],
        },
    }
};

export default initialState;