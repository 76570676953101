import * as CONSTANTS from "./constants";
import { apiGet } from "@common/crud/actions";

export const fetchTableEvent = params => {
    return dispatch => {
        dispatch(fetchActionLoading());
        dispatch(apiGet('events', params, {}, fetchAction));
    };
}

const fetchActionLoading = () => {
    return {
        type: CONSTANTS.EVENT_FETCH_LOADING,
        payload: {}
    }
}

const fetchAction = response => {
    return {
        type: CONSTANTS.EVENT_FETCH,
        payload: response
    };
}

export const detailEvent = id => {
    return dispatch => {
        dispatch(detailActionLoading());
        dispatch(apiGet(`events/${id}`, {}, {}, detailAction));
    };
}

const detailAction = response => {
    return {
        type: CONSTANTS.EVENT_DETAIL,
        payload: response
    };
}

const detailActionLoading = () => {
    return {
        type: CONSTANTS.EVENT_DETAIL_LOADING,
        payload: null
    };
}