import React, { Component } from 'react';
import { DataEmpty, ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { AntCard, Loading } from "@layouts";
import { BarChartOutlined, InfoCircleOutlined, TableOutlined } from "@ant-design/icons";
import { Tabs } from 'antd';
import { DATA_TYPE, } from "@ultis/constants";
import { AnalysisFilterTimeComponent } from "../AnalysisFilterTimeComponent";
import * as Plant from "@features/Plant";
import { getChartConfigByType } from "@features/Plant";

const { TabPane } = Tabs;

class CustomComponent extends Component {
    render = () => {
        const { common } = this.props;
        const parentElementId = "analysis-detail";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        const { plant, setDetailParams, params, dataType, } = this.props;
        const { from, to } = params;
        let chartConfig = getChartConfigByType(dataType);
        let { ranges, storeField, title } = chartConfig;
        let dataChart = plant.meta[storeField] ?? {};

        let { options, loading } = dataChart;

        return (
            <AntCard
                id={parentElementId}
                className={"analysis-default card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <InfoCircleOutlined/> Analysis details
                        </div>
                        <ToolboxControl parentElementId={parentElementId}/>
                    </div>
                }
            >
                <div className="card-custom-body">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={
                            <span><BarChartOutlined/>Chart</span>
                        } key="1">
                            <div className="tab-title tab-group">
                                {title}
                            </div>
                            <div className="tab-filter tab-group">
                                <AnalysisFilterTimeComponent
                                    ranges={ranges}
                                    setDetailParams={setDetailParams}
                                    from={from}
                                    to={to}
                                />
                            </div>

                            <div className="tab-content">
                                {loading ? <Loading overwrite/> : null}
                                <ChartData
                                    dataType={dataType}
                                    options={options}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab={
                            <span><TableOutlined/>Table</span>
                        } key="2">
                            <div className="tab-title tab-group">
                                {title}
                            </div>
                            <div className="tab-filter tab-group">
                                <AnalysisFilterTimeComponent
                                    ranges={ranges}
                                    setDetailParams={setDetailParams}
                                    from={from}
                                    to={to}
                                />
                            </div>
                            <div className="tab-content">
                                <TableData
                                    dataType={dataType}
                                    loading={loading}
                                    options={options}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </AntCard>
        )
    }
}

const ChartData = ({ dataType, options }) => {
    let component = <DataEmpty title={"No available."}/>
    switch (dataType) {
        case DATA_TYPE.ACTUAL_PRODUCTION_AND_IRRADIATION:
            component = <Plant.ChartActualProductionIrradiation options={options}/>
            break;
        case DATA_TYPE.STRING_MONITORING:
            component = <Plant.ChartStringMonitoring options={options}/>
            break;
        case DATA_TYPE.IV_CURVE:
            component = <Plant.ChartIVCurve options={options}/>
            break;
        case DATA_TYPE.SPECIFIC_YIELD:
            component = <Plant.ChartSpecificYield options={options}/>
            break;
        case DATA_TYPE.ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION:
            component = <Plant.ChartActualProductionVsBudgetProduction options={options}/>
            break;
        case DATA_TYPE.CARBON_OFFSET:
            component = <Plant.ChartCarbonOffset options={options}/>
            break;
        case DATA_TYPE.PERFORMANCE_RATIO_TRACKING:
            component = <Plant.ChartPerformanceRatioTracking options={options}/>
            break;
        case DATA_TYPE.DAILY_IO_DIAGRAM:
            component = <Plant.ChartDailyIODiagram options={options}/>
            break;
        default:
            break
    }

    return component;
}

const TableData = ({ dataType, options, loading }) => {
    let component = <DataEmpty title={"No available."}/>
    switch (dataType) {
        case DATA_TYPE.ACTUAL_PRODUCTION_AND_IRRADIATION:
            component = <Plant.TableActualProductionIrradiation loading={loading} options={options}/>
            break;
        case DATA_TYPE.SPECIFIC_YIELD:
            component = <Plant.TableSpecificYield loading={loading} options={options}/>
            break;
        case DATA_TYPE.ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION:
            component = <Plant.TableActualProductionVsBudgetProduction options={options}/>
            break;
            component = <Plant.TableCarbonOffset options={options}/>
        case DATA_TYPE.CARBON_OFFSET:
            break;
        case DATA_TYPE.PERFORMANCE_RATIO_TRACKING:
            component = <Plant.TablePerformanceRatioTracking loading={loading} options={options}/>
            break;
        case DATA_TYPE.DAILY_IO_DIAGRAM:
            component = <DataEmpty title={"No available."}/>
            break;
        default:
            break
    }

    return component;
}

const mapStateToProps = state => {
    return {
        common: state.common,
        plant: state.plant,
        analysis: state.analysis,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);