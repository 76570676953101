import React, { Component } from 'react';
import { connect } from "react-redux";
import { AreaChartOutlined, SearchOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { AntCard, ToolboxControl, TagSLA, AntButton, TagTicketStatus } from "@layouts";
import { Table, Input, Select, Row, Col, DatePicker, Tag } from "antd";

const { Option } = Select;


class CustomComponent extends Component {
    render = () => {
        const { common, meta } = this.props;
        const parentElementId = "plant-InverterMonitoring";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Data from inverter
        const { loading, } = meta.inverterPerformance;

        const {
            ticketParams,
            onChangeKeyword,
            onChangeDueOn,
            onChangeTicketStatus,
            onChangeDeviceType,
            onChangeTableTickets,

            onClickNewTicket,
            onClickEditTicket,
        } = this.props;

        const {
            keyword,
            ticketStatus,
            equipment,
            dueOnFrom,
            dueOnTo,
        } = ticketParams;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> Tickets
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            btnAddShow={true}
                            btnAddText={"New ticket"}
                            btnAddClick={onClickNewTicket}
                        />
                    </div>
                }
            >
                <div className="card-custom-body">
                    <div className="search-group">
                        <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                            <Col xs={24} xl={12}>
                                <div className="search-input input-keyword">
                                    <span className="input-label">Keyword:</span>
                                    <Input
                                        placeholder="Search ticket by name, content"
                                        suffix={<SearchOutlined/>}
                                        value={keyword}
                                        onChange={onChangeKeyword}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} xl={12}>
                                <div className="search-input input-date">
                                    <span className="input-label">Due on:</span>
                                    <DatePicker.RangePicker
                                        style={{ width: "100%" }}
                                        format="YYYY-MM-DD"
                                        value={[dueOnFrom, dueOnTo]}
                                        onCalendarChange={this.onCalendarChange}
                                        onChange={onChangeDueOn}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                            <Col xs={24} xl={12}>
                                <div className="search-input input-status">
                                    <span className="input-label">Ticket status:</span>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="Ticket status"
                                        value={ticketStatus}
                                        style={{ width: "100%" }}
                                        tagRender={tagRender}
                                        onChange={onChangeTicketStatus}
                                    >
                                        <Option value={10}>Open</Option>
                                        <Option value={20}>Processing</Option>
                                        <Option value={90}>Close</Option>
                                    </Select>
                                </div>
                            </Col>
                            <Col xs={24} xl={12}>
                                <div className="search-input input-equipment">
                                    <span className="input-label">Equipment:</span>
                                    <Select
                                        style={{ width: "100%" }}
                                        mode="multiple"
                                        allowClear
                                        placeholder="Ticket equipment"
                                        value={equipment}
                                        onChange={onChangeDeviceType}
                                    >
                                        <Option value="all">All</Option>
                                        <Option value="inverter">Inverter</Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Table
                        size={"small"}
                        columns={columns(onClickEditTicket)}
                        dataSource={tickets()}
                        pagination={pagination()}
                        onChange={onChangeTableTickets}
                        loading={loading}
                        rowKey={"id"}
                        scroll={{ x: 1200 }}
                    >
                    </Table>
                </div>
            </AntCard>
        )
    }
}

const tagRender = props => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    let color;
    switch (value) {
        case 10:
            color = "green"
            break
        case 20:
            color = "blue"
            break
        case 90:
        default:
            color = "default"
            break;
    }

    return (
        <Tag
            color={color}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
                //backgroundColor: "unset"
            }}
        >
            {label}
        </Tag>
    );
};

const pagination = () => {
    return {
        current: 5,
        pageSize: 25,
        total: 250,
        showSizeChanger: false,
    }
}

const tickets = () => {
    return [
        {
            "id": 5678,
            "sla": 40,
            "name": "Ground fall at String 1 of Inverter #10",
            "person": "Nguyễn Văn A",
            "role": "O&M",
            "equipment": "Inverter",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 10,
        },
        {
            "id": 1234,
            "sla": 10,
            "name": "Cleaning PV module",
            "person": "Nguyễn Văn B",
            "role": "AM",
            "equipment": "PV Module",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 20,
        },
        {
            "id": 9123,
            "sla": 20,
            "name": "Check Fire Protection system",
            "person": "Nguyễn Văn C",
            "role": "EM",
            "equipment": "Fire Protection",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 90,
        },
        {
            "id": 900,
            "sla": 40,
            "name": "Ground fall at String 1 of Inverter #10",
            "person": "Nguyễn Văn A",
            "role": "O&M",
            "equipment": "Inverter",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 10,
        },
        {
            "id": 800,
            "sla": 10,
            "name": "Cleaning PV module",
            "person": "Nguyễn Văn B",
            "role": "AM",
            "equipment": "PV Module",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 20,
        },
        {
            "id": 700,
            "sla": 20,
            "name": "Check Fire Protection system",
            "person": "Nguyễn Văn C",
            "role": "EM",
            "equipment": "Fire Protection",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 90,
        },
        {
            "id": 600,
            "sla": 40,
            "name": "Ground fall at String 1 of Inverter #10",
            "person": "Nguyễn Văn A",
            "role": "O&M",
            "equipment": "Inverter",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 10,
        },
        {
            "id": 500,
            "sla": 10,
            "name": "Cleaning PV module",
            "person": "Nguyễn Văn B",
            "role": "AM",
            "equipment": "PV Module",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 20,
        },
        {
            "id": 400,
            "sla": 20,
            "name": "Check Fire Protection system",
            "person": "Nguyễn Văn C",
            "role": "EM",
            "equipment": "Fire Protection",
            "createdOn": "2022-05-01",
            "dueOn": "2022-05-20",
            "siteName": "CTY TNHH CK DL TOÀN CẦU",
            "plantName": "ROOFTOP PV 1",
            "ownerUser": "Mr. X",
            "status": 90,
        }
    ];
}

const columns = (onClickEditTicket) => {
    return [
        {
            fixed: "left",
            ellipsis: true,
            title: "Number",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 80,
            render: (value, item) => <TagSLA text={value} level={item.sla}/>,
        },
        {
            fixed: "left",
            ellipsis: true,
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 85,
            render: (value, item) => <TagTicketStatus level={item.status}/>,
        },
        {
            //ellipsis : true,
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            ellipsis: true,
            title: "Person in charge",
            dataIndex: "person",
            key: "person",
            width: 150,
        },
        {
            ellipsis: true,
            title: "Equipment",
            dataIndex: "equipment",
            key: "equipment",
            width: 150,
        },
        {
            ellipsis: true,
            title: "Created on",
            dataIndex: "createdOn",
            key: "createdOn",
            align: "center",
            width: 100,
        },
        {
            ellipsis: true,
            title: "Due on",
            dataIndex: "dueOn",
            key: "dueOn",
            align: "center",
            width: 100,
        },
        {
            //ellipsis : true,
            title: "Site",
            dataIndex: "siteName",
            key: "siteName",
        },
        {
            //ellipsis : true,
            title: "Plant",
            dataIndex: "plantName",
            key: "plantName",
        },
        {
            ellipsis: true,
            title: "Manager",
            dataIndex: "ownerUser",
            key: "ownerUser",
            width: 150,
        },
        {
            fixed: "right",
            ellipsis: true,
            title: "Action",
            align: "center",
            width: 80,
            render: () => <AntButton
                ghost
                type="primary"
                size={"small"}
                icon={<EditOutlined/>}
                onClick={onClickEditTicket}
            />,
        },
    ];
}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);