import React, { Component } from 'react';
import { Col, Row, Skeleton } from "antd";
import * as PlantComponents from "../../components";

class President extends Component {
    props = this['props'];
    render = () => {
        const { handleChangeTimeActual, onChangeResizeChart } = this.props;

        const { detail, meta } = this.props.plant;

        const { data, loading, } = detail;

        return (
            <div className="features feature-plant">
                <h1 className="page-title">
                    {loading ? <Skeleton.Input active={loading}/> : data.name}
                </h1>
                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24} xl={15}>
                        <PlantComponents.PlantSystemArchitecture
                            //handleChangeTime={handleChangeTimeActual}
                            //onChangeResizeChart={onChangeResizeChart}
                            //meta={meta}
                        />
                    </Col>
                    <Col xs={24} xl={9}>
                        <PlantComponents.PlantControlStatus
                            //meta={meta}
                        />
                    </Col>
                </Row>
            </div>

        )
    }
}

export default President;