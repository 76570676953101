import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { AreaChartOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import { Badge, Calendar, Col, Row, Select } from 'antd';
import { DEFAULT_FORMAT_DATE } from "@ultis/constants";
import helpers from "@ultis/helpers";

class CustomComponent extends Component {
    props = this['props'];

    render = () => {
        const { common } = this.props;
        const parentElementId = "plant-PerformanceRatioTracking";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        const { meta, handleChangeTime, onSelectCellCalendar } = this.props;


        // Init chart data
        const { loading, options } = meta.performanceRatioTracking;
        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> Ticket Due
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            handleChangeTime={handleChangeTime}
                        />
                    </div>
                }
            >
                <div className="card-custom-body">
                    {loading ? <Loading overwrite/> : null}
                    <div className="ticket-calendar">
                        <Calendar
                            headerRender={headerCustom}
                            dateCellRender={this.dateCellRender}
                            onSelect={onSelectCellCalendar}
                        />
                    </div>
                </div>
            </AntCard>
        )
    }

    /**
     * Render cell data
     * @param value
     * @returns {JSX.Element}
     */
    dateCellRender = value => {
        let ticketsDue = this.props.ticketsDue ?? [];
        let selectedRoles = this.props.selectedRoles ?? [];
        let listData = ticketsDue[value.format(DEFAULT_FORMAT_DATE)] ?? [];
        listData = listData.filter(data => {
            return selectedRoles[data.assignUserRole]
        });

        return (
            <ul className="events">
                {
                    listData.map((item, idx) => (
                        <li key={idx}>
                            <Badge color={helpers.stringToColor(item.assignUserName)} text={item.name}/>
                        </li>
                    ))
                }
            </ul>
        );
    };
}

const mapStateToProps = state => {
    return {
        common: state.common,
        ticketsManagement: state.ticketsManagement,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);

const headerCustom = ({ value, type, onChange, onTypeChange }) => {
    {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        const current = value.clone();
        //const localeData = value.localeData();
        const months = [];

        for (let i = 0; i < 12; i++) {
            current.month(i);

            //months.push(localeData.monthsParse(current));
            months.push(current.format("MM"));
        }

        for (let index = start; index < end; index++) {
            monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                </Select.Option>,
            );
        }

        const month = value.month();
        const year = value.year();
        const options = [];

        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }

        return (
            <div
                className="ticket-calendar-header"
            >
                <Row gutter={8}>
                    {/*<Col>
                        <Radio.Group
                            onChange={(e) => onTypeChange(e.target.value)}
                            value={type}
                        >
                            <Radio.Button value="month">Month</Radio.Button>
                            <Radio.Button value="year">Year</Radio.Button>
                        </Radio.Group>
                    </Col>*/}
                    <Col>
                        <Select
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={(selectedMonth) => {
                                const newValue = value.clone();
                                newValue.month(parseInt(selectedMonth, 10));
                                onChange(newValue);
                            }}
                        >
                            {monthOptions}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            onChange={(newYear) => {
                                const now = value.clone().year(Number(newYear));
                                onChange(now);
                            }}
                            value={String(year)}
                        >
                            {options}
                        </Select>
                    </Col>
                </Row>
            </div>
        );
    }
}