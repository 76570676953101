import * as CONSTANTS from './constants'
import { apiGet } from "@common/crud";

export const getSiteDetail = siteId => {
    return dispatch => {
        dispatch(detailSiteActionLoading());
        dispatch(apiGet('sites/' + siteId, {}, {}, detailSiteAction));
    };
}

const detailSiteAction = response => {
    return {
        type: CONSTANTS.SITE_DETAIL,
        payload: response
    };
}

const detailSiteActionLoading = () => {
    return {
        type: CONSTANTS.SITE_DETAIL_LOADING,
        payload: null
    };
}