import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import { LOCATION_CHANGE } from "connected-react-router";

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
            };

        /**
         * Reset action
         */
        case CONSTANTS.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        default:
            return state;
    }
}