import React, { Component } from 'react';
import { Loading, ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { AntCard, TagSeverity, AntButton, AntTooltip } from "@layouts";
import { DesktopOutlined, InfoOutlined, TagsOutlined } from "@ant-design/icons";
import { Table } from 'antd';
import { detailEvent, fetchTableEvent, } from "@features/Event/redux";
import { Drawer, Tag } from 'antd';
import { withRouter } from "react-router-dom";

class CustomComponent extends Component {
    props = this['props'];

    constructor(props) {
        super(props);
        this.state = {
            isVisibleFormDetail: false,
        }
    }

    /**
     * On click detail event
     */
    onClickDetailEvent = e => {
        this.setState({
            ...this.state,
            isVisibleFormDetail: true
        });

        this.props.detailEvent(e.currentTarget.value);
    }

    /**
     * On close detail event
     */
    onCloseDetailEvent = () => {
        this.setState({
            ...this.state,
            isVisibleFormDetail: false
        })
    }

    handleChangeTable = (pagination, filters, sorter) => {
        // Get route params
        let { plantId, siteId } = this.props.match.params;
        plantId = plantId ?? null;
        siteId = siteId ?? null;

        // Fetch event by query
        this.props.fetchTableEvent({
            siteId: siteId,
            plantId: plantId,
            page: pagination.current,
            limit: pagination.pageSize,
            orderBy: sorter.field ?? "id",
            sortBy: sorter.order === "ascend" ? "asc" : "desc",
            //...filters,
        })
    }

    render = () => {
        const parentElementId = "site-event";
        const fullScreenClass = parentElementId === this.props.common.fullScreen.elementId ? this.props.common.fullScreen.class : '';

        const { event, } = this.props;

        // Get current scope data
        const { data, pagination, loading } = event.list;
        const eventDetail = event.detail;
        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DesktopOutlined/> Event
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-height full-width">
                    <Table
                        size="small"
                        columns={columns(this.onClickDetailEvent)}
                        showSizeChanger={false}
                        dataSource={data}
                        rowKey="id"
                        loading={loading}
                        pagination={pagination}
                        onChange={
                            (pagination, filters, sorter) =>
                                this.handleChangeTable(pagination, filters, sorter)
                        }
                    >
                    </Table>
                </div>
                <EventDetail
                    loading={eventDetail.loading}
                    data={eventDetail.data}
                    isVisible={this.state.isVisibleFormDetail}
                    onClose={this.onCloseDetailEvent}
                />
            </AntCard>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTableEvent: params => {
            dispatch(fetchTableEvent(params));
        },
        detailEvent: id => {
            dispatch(detailEvent(id));
        },
    };
}


const mapStateToProps = state => {
    return {
        common: state.common,
        event: state.event,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomComponent));

const EventDetail = ({ loading, data, isVisible, onClose }) => {
    data = data ?? {}
    return (
        <Drawer width={640}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={isVisible}
        >
            {
                loading ? <Loading/> :
                    <div>
                        <p>
                            Event <span className="text-highlight">#{data.id}</span>
                        </p>
                        <p className="site-description-item-profile-p">Summary</p>
                        <div className="description-content">
                            <DescriptionItem title="Event Name" content={data.eventName}/>
                            <DescriptionItem title="Scope" content={data.scope}/>
                            <DescriptionItem
                                title="Severity"
                                content={
                                    <span><TagSeverity level={data.severity}/> {data.severityText}</span>
                                }
                            />
                            <DescriptionItem title="Site Name" content={data.siteName}/>
                            <DescriptionItem title="Plant Name" content={data.plantName}/>
                            <DescriptionItem title="Beginning" content={data.beginningTime}/>
                        </div>
                        <p className="site-description-item-profile-p">Tickets</p>
                        <div className="description-content">
                            <div>
                                <Tag color="red">Le Vinh</Tag>
                                <Tag color="green">Phuc</Tag>
                                <Tag color="volcano">Huu Bao</Tag>
                                <Tag color="cyan">Tran Tuan</Tag>
                                <Tag color="gold">Long</Tag>
                                <Tag color="blue">Cuong</Tag>
                                <Tag color="geekblue">Dao Thanh</Tag>
                            </div>
                        </div>
                    </div>
            }
        </Drawer>
    )
}

const DescriptionItem = ({ title, content }) => (
    <div
        className="site-description-item-profile-wrapper"
        style={{
            marginBottom: 0
        }}
    >
        <p
            className="site-description-item-profile-p-label"
            style={{
                width: 100
            }}
        >{title}:</p>
        {content}
    </div>
);

const columns = onShowDetail => {
    return [
        {
            sorter: true,
            align: 'center',
            width: 150,
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            render: (value, item) => <div><TagSeverity level={value}/> {item.severityText}</div>,
        },
        {
            title: 'Scope',
            dataIndex: 'scope',
            key: 'scope',
        },
        {
            title: 'Event',
            dataIndex: 'eventName',
            key: 'eventName'
        },
        {
            sorter: true,
            align: 'center',
            title: 'Beginning',
            dataIndex: 'beginningTime',
            key: 'beginningTime',
        },
        {
            title: 'Plant & Site',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: "100px",
            render: (value, item) => <div className="group-button">
                <AntTooltip placement="right" title="Detail event">
                    <AntButton
                        size="small"
                        icon={<InfoOutlined/>}
                        type="primary" ghost
                        value={item.id}
                        onClick={onShowDetail}
                    >
                    </AntButton>
                </AntTooltip>
                <AntTooltip placement="right" title="Assign ticket">
                    <AntButton
                        className="btn-success-ghost"
                        size="small"
                        icon={<TagsOutlined/>}
                        type="danger" ghost
                    />
                </AntTooltip>
            </div>
        },
    ]
}