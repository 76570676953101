import React, { Component } from 'react';
import { Select, Input, Row, Col, DatePicker, Checkbox, Badge, Timeline, Modal, Drawer } from 'antd';
import { AntButton, AntForm, AntFormItem, AntInput, AntInputTextArea, } from "@layouts";
import { ClockCircleOutlined } from "@ant-design/icons";

class CustomComponent extends Component {
    props = this['props'];
    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    /**
     * On click new ticket
     */
    onClickNewTicket = () => {
        this.setState({
            ...this.state,
        });

        this.props.onClickNewTicket();
    }

    /**
     * On click edit ticket
     */
    onClickEditTicket = () => {
        this.setState({
            ...this.state,
        });
        this.props.onClickEditTicket();
    }

    /**
     * On submit form ticket
     */
    onSubmitTicket = () => {
        console.log("onSubmitTicket")
        this.props.onSubmitTicket();
    }

    /**
     * On click hide ticket
     */
    onCloseTicket = () => {
        this.setState({
            ...this.state,
        })
        this.props.onCloseTicket();
    }

    render = () => {
        let {
            isVisibleTicket, // Is display modal
            ticket, // Current ticket detail

            errors,
            createLoading,
            updateLoading,
            deleteLoading,
            onShowConfirmDelete,
            isDetail,

            listPlant,
            listEvent,
            listSLA,
            listEquipment,
            listStatus,
            listUserGroup,

            currentUser,// Current login user
        } = this.props;

        errors = errors ?? {};
        ticket = ticket ?? {};

        const { name, price, unit, description } = ticket;

        return (
            <Drawer
                className={"modal-ticket"}
                width={960}
                placement="right"
                maskClosable={false}
                onClose={this.onCloseTicket}
                visible={isVisibleTicket}
                title="Ticket detail"
            >
                <AntForm
                    className="form-center form-custom"
                    layout="vertical"
                    onFinish={(data => this.props.onFinish(data))}
                    initialValues={{ name: name, price: price, unit: unit, description: description }}
                >
                    <AntFormItem
                        required={true}
                        label="Ticket Name"
                        name="name"
                        value={name}
                        errors={errors.name}
                    >
                        <AntInput/>
                    </AntFormItem>
                    <Row gutter={8}>
                        <Col xs={24} xl={12}>
                            <AntFormItem
                                required={true}
                                label="Site"
                                name="plantId"
                                errors={errors.plantId}
                            >
                                <Select
                                    placeholder={"---"}
                                    disabled={true}
                                    defaultValue={listPlant ? listPlant[0].id : null}
                                >
                                    {
                                        listPlant ?
                                            listPlant.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.id}
                                                                   key={index}>{item.name}</Select.Option>
                                                )
                                            }) : null
                                    }
                                </Select>
                            </AntFormItem>
                        </Col>
                        <Col xs={24} xl={12}>
                            <AntFormItem
                                required={true}
                                label="Plant"
                                name="plantId"
                                errors={errors.plantId}
                            >
                                <Select
                                    placeholder={"---"}
                                    disabled={true}
                                    defaultValue={listPlant ? listPlant[0].id : null}
                                >
                                    {
                                        listPlant ?
                                            listPlant.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.id}
                                                                   key={index}>{item.name}</Select.Option>
                                                )
                                            }) : null
                                    }
                                </Select>
                            </AntFormItem>
                        </Col>
                    </Row>

                    <AntFormItem
                        label="Event"
                        name="eventId"
                        errors={errors.eventId}
                    >
                        <Select
                            placeholder={"---"}
                            disabled={true}
                            defaultValue={listEvent ? listEvent[0].id : null}
                        >
                            {
                                listEvent ?
                                    listEvent.map((item, index) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    }) : null
                            }
                        </Select>
                    </AntFormItem>
                    <AntFormItem
                        required={true}
                        label="Equipment"
                        name="equipmentId"
                        errors={errors.plantId}
                    >
                        <Select
                            placeholder={"Select equipment"}
                            defaultValue={null}
                        >
                            {
                                listEquipment ?
                                    listEquipment.map((item, index) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    }) : null
                            }
                        </Select>
                    </AntFormItem>
                    <AntFormItem
                        required={true}
                        label="Status"
                        name="statusId"
                        errors={errors.plantId}
                    >
                        <Select
                            placeholder={"Select status"}
                            defaultValue={null}
                        >
                            {
                                listStatus ?
                                    listStatus.map((item, index) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    }) : null
                            }
                        </Select>
                    </AntFormItem>
                    <Row gutter={8}>
                        <Col xs={24} xl={8}>
                            <AntFormItem
                                required={true}
                                label="SLA"
                                name="slaId"
                                errors={errors.slaId}
                            >
                                <Select
                                    placeholder="Select SLA level"
                                >
                                    {
                                        listSLA ?
                                            listSLA.map((item, index) => {
                                                return (
                                                    <Select.Option value={item.id}
                                                                   key={index}>{item.name}</Select.Option>
                                                )
                                            }) : []
                                    }
                                </Select>
                            </AntFormItem>
                        </Col>
                        <Col xs={24} xl={8}>
                            <AntFormItem
                                required={true}
                                label="Start Date"
                                name="startDate"
                                errors={errors.startDate}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                />
                            </AntFormItem>
                        </Col>
                        <Col xs={24} xl={8}>
                            <AntFormItem
                                required={true}
                                label="Due Date"
                                name="dueDate"
                                errors={errors.dueDate}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                />
                            </AntFormItem>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col xs={24} xl={12}>
                            <AntFormItem
                                required={true}
                                label="Person in Charge"
                                name="assignUserId"
                                errors={errors.unit}
                            >
                                <SelectGroupUser
                                    placeholder="Select user"
                                    defaultValue={null}
                                    listUserGroup={listUserGroup}
                                />
                            </AntFormItem>
                        </Col>
                        <Col xs={24} xl={12}>
                            <AntFormItem
                                style={{
                                    marginTop: 22
                                }}
                                valuePropName="checked"
                                name="isSendEmail"
                                errors={errors.isSendEmail}
                            >
                                <Checkbox>Send an email to people</Checkbox>
                            </AntFormItem>
                        </Col>
                    </Row>

                    <AntFormItem
                        label="Description"
                        name="description"
                        errors={errors.description}
                    >
                        <AntInputTextArea rows={5}/>
                    </AntFormItem>
                    <AntFormItem
                        required={true}
                        label="Update history"
                    >
                        <UpdateHistories
                            histories={ticket.histories}
                        />
                    </AntFormItem>
                </AntForm>
                <div className="text-center group-button">
                    {
                        ticket ?
                            <AntButton
                                className="btn-primary"
                                onClick={this.onSubmitTicket}
                                loading={updateLoading}
                            >
                                Update
                            </AntButton> :
                            <AntButton
                                className="btn-success"
                                onClick={this.onSubmitTicket}
                                loading={createLoading}
                            >
                                Save
                            </AntButton>
                    }
                    <AntButton
                        onClick={this.onCloseTicket}
                    >
                        Cancel
                    </AntButton>
                </div>
            </Drawer>
        )
    }
}

export default CustomComponent;

const UpdateHistories = ({ histories }) => {
    return (
        histories ?
            <div className="update-histories">
                <Timeline mode="right">
                    {
                        histories.map((history, idx) => {
                            return (
                                <Timeline.Item
                                    key={idx}
                                    className="history-item"
                                    dot={<ClockCircleOutlined/>}
                                >
                                    <span className="description">{history.description}</span>
                                    <span className="update-at">{history.updateAt}</span>
                                </Timeline.Item>
                            )
                        })
                    }
                </Timeline>

            </div> : null
    )
}

/**
 * Select Group User
 * @param defaultValue
 * @param listUserGroup
 * @param placeholder
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
const SelectGroupUser = ({ defaultValue, listUserGroup, placeholder, onChange }) => {
    return (
        <Select
            showSearch
            placeholder={placeholder}
            defaultValue={defaultValue}
            filterOption={
                (input, option) => {
                    return option.children ? option.children.toLowerCase().includes(input.toLowerCase()) : false
                }
            }
            onChange={onChange}
        >
            {
                listUserGroup ?
                    listUserGroup.map((group, idxGroup) => {
                        return (
                            <Select.OptGroup label={group.roleName} key={idxGroup}>
                                {
                                    group.users.map((user, idxUser) => {
                                        return (
                                            <Select.Option
                                                value={user.id}
                                                key={idxGroup + "-idxUser-" + idxUser}>
                                                {user.name}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select.OptGroup>
                        )
                    }) : []
            }
        </Select>
    )
}
