import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export const reducer = (state = initialState, action) => {
    let payload = action.payload
    switch (action.type) {
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        /**
         * Current Billing
         */
        case CONSTANTS.GET_CURRENT_BILLING:
            return {
                ...state,
                currentBilling: {
                    ...state.currentBilling,
                    data: action.payload ?? {},
                    loading: false
                },
            }

        case CONSTANTS.GET_CURRENT_BILLING_LOADING:
            return {
                ...state,
                currentBilling: {
                    ...state.currentBilling,
                    loading: true
                },
            }

        /**
         * Cost trend
         */
        case CONSTANTS.GET_COST_TREND:
            let groupBy = payload ? payload.groupBy : null
            let series = payload ? payload.series : []
            return {
                ...state,
                costTrend: {
                    ...state.costTrend,
                    options: {
                        groupBy: groupBy ?? null,
                        series: series ?? [],
                    },
                    loading: false
                },
            }

        case CONSTANTS.GET_COST_TREND_LOADING:
            return {
                ...state,
                costTrend: {
                    ...state.costTrend,
                    loading: true
                },
            }

        /**
         * Invoice list
         */
        case CONSTANTS.GET_INVOICE_LIST:
            payload = payload ?? {}
            return {
                ...state,
                invoiceList: {
                    ...state.invoiceList,
                    loading: false,
                    data: payload.listInvoice ?? []
                },
            }

        case CONSTANTS.GET_INVOICE_LIST_LOADING:
            return {
                ...state,
                invoiceList: {
                    ...state.invoiceList,
                    loading: true
                },
            }

        /**
         * Invoice detail
         */
        case CONSTANTS.GET_INVOICE_DETAIL:
            payload = payload ?? {}
            return {
                ...state,
                invoiceDetail: {
                    ...state.invoiceDetail,
                    loading: false,
                    data: payload
                },
            }

        case CONSTANTS.GET_INVOICE_DETAIL_LOADING:
            return {
                ...state,
                invoiceDetail: {
                    ...state.invoiceDetail,
                    loading: true
                },
            }
        default:
            return state;
    }
}