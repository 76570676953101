import * as CONSTANTS from './constants'
import { apiGet, mockup } from "@common/crud";
import { DATA_TYPE } from "@ultis/constants";

export const getPlantDetail = plantId => {
    return dispatch => {
        dispatch(detailPlantActionLoading());
        dispatch(apiGet('plants/' + plantId, {}, {}, detailPlantAction));
    };
}

const detailPlantAction = response => {
    return {
        type: CONSTANTS.PLANT_DETAIL,
        payload: response
    };
}

const detailPlantActionLoading = () => {
    return {
        type: CONSTANTS.PLANT_DETAIL_LOADING,
        payload: null
    };
}

export const getPlantData = params => {
    // Get params
    let { silent, plantId, from, to, groupBy, dataType } = params;

    // Need show loading animation when fetch data
    const loading = !silent;

    // Render endpoint
    const endPoint = `plants/${plantId}/detail/`;

    return dispatch => {
        switch (dataType) {
            case DATA_TYPE.ACTUAL_PRODUCTION_AND_IRRADIATION:
                if (loading) {
                    dispatch(chartActualActionLoading())
                }
                dispatch(apiGet(endPoint, {
                    from: from,
                    to: to,
                    groupBy: groupBy,
                    dataType: dataType,
                }, {}, chartActualAction))
                break;
            case DATA_TYPE.ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION:
                if (loading) {
                    dispatch(chartActualProductionVsBudgetProductionLoading())
                }
                dispatch(apiGet(endPoint, {
                    from: from,
                    to: to,
                    groupBy: groupBy,
                    dataType: dataType,
                }, {}, chartActualProductionVsBudgetProductionAction))
                break;
            case DATA_TYPE.CARBON_OFFSET:
                if (loading) {
                    dispatch(chartCarbonOffsetLoading())
                }
                dispatch(apiGet(endPoint, {
                    from: from,
                    to: to,
                    groupBy: groupBy,
                    dataType: dataType,
                }, {}, chartCarbonOffsetAction))
                break;
            case DATA_TYPE.GAUGES_MONITORING:
                if (loading) {
                    dispatch(gaugesTechnicalMonitoringActionLoading())
                }
                dispatch(apiGet(endPoint, {
                    dataType: dataType,
                }, {}, gaugesTechnicalMonitoringAction))
                break;
            case DATA_TYPE.STRING_MONITORING:
                if (loading) {
                    dispatch(chartStringMonitoringLoading())
                }
                dispatch(apiGet(endPoint, {
                    dataType: dataType,
                }, {}, chartStringMonitoringAction))
                break;
            case DATA_TYPE.IV_CURVE:
                if (loading) {
                    dispatch(chartIVCurveLoading)
                }
                dispatch(mockup(mockupIV, chartIVCurveAction));
                /*dispatch(apiGet(endPoint, {
                    dataType: dataType,
                }, {}, chartIVCurveAction))*/
                break;
            case DATA_TYPE.INVERTER_MONITORING:
                if (loading) {
                    dispatch(inverterMonitoringActionLoading())
                }
                dispatch(apiGet(endPoint, {
                    dataType: dataType,
                }, {}, inverterMonitoringAction))
                break;
            case DATA_TYPE.SPECIFIC_YIELD:
                if (loading) {
                    dispatch(chartSpecificYieldActionLoading())
                }
                dispatch(apiGet(endPoint, {
                    from: from,
                    to: to,
                    groupBy: groupBy === 'hour' ? 'date' : groupBy,
                    dataType: dataType,
                }, {}, chartSpecificYieldAction))
                break;
            case DATA_TYPE.DAILY_IO_DIAGRAM:
                if (loading) {
                    dispatch(chartDailyIODiagramActionLoading())
                }
                dispatch(apiGet(endPoint, {
                    from: from,
                    to: to,
                    groupBy: groupBy,
                    dataType: dataType,
                }, {}, chartDailyIODiagramAction))
                break;
            case DATA_TYPE.PERFORMANCE_RATIO_TRACKING:
                if (loading) {
                    dispatch(chartPerformanceRatioTrackingLoading())
                }
                dispatch(apiGet(endPoint, {
                    from: from,
                    to: to,
                    groupBy: groupBy === 'hour' ? 'date' : groupBy,
                    dataType: dataType,
                }, {}, chartPerformanceRatioTrackingAction))
                break;
            case DATA_TYPE.GAUGES_PERFORMANCE:
                if (loading) {
                    dispatch(gaugesAssetPerformanceLoading())
                }
                dispatch(apiGet(endPoint, {
                    dataType: dataType,
                }, {}, gaugesAssetPerformanceAction))
                break;
            case DATA_TYPE.INVERTER_PERFORMANCE:
                if (loading) {
                    dispatch(inverterPerformanceActionLoading())
                }
                dispatch(apiGet(endPoint, {
                    dataType: dataType,
                }, {}, inverterPerformanceAction))
                break;
            default:
                break
        }
    };
}

const chartActualAction = response => {
    return {
        type: CONSTANTS.CHART_ACTUAL,
        payload: response.data
    };
}

const chartActualActionLoading = () => {
    return {
        type: CONSTANTS.CHART_ACTUAL_LOADING,
        payload: null
    };
}

const gaugesTechnicalMonitoringAction = response => {
    return {
        type: CONSTANTS.GAUGES_TECHNICAL_MONITORING,
        payload: response.data
    };
}

const gaugesTechnicalMonitoringActionLoading = () => {
    return {
        type: CONSTANTS.GAUGES_TECHNICAL_MONITORING_LOADING,
        payload: null
    };
}

const chartStringMonitoringAction = response => {
    return {
        type: CONSTANTS.CHART_STRING_MONITORING,
        payload: response.data
    };
}

const chartStringMonitoringLoading = () => {
    return {
        type: CONSTANTS.CHART_STRING_MONITORING_LOADING,
        payload: null
    };
}

const chartIVCurveAction = response => {
    return {
        type: CONSTANTS.CHART_IV_CURVE,
        payload: response.data
    };
}

const chartIVCurveLoading = () => {
    return {
        type: CONSTANTS.CHART_IV_CURVE_LOADING,
        payload: null
    };
}

const inverterMonitoringAction = response => {
    return {
        type: CONSTANTS.LIST_INVERTER_MONITORING,
        payload: response.data
    };
}

const inverterMonitoringActionLoading = () => {
    return {
        type: CONSTANTS.LIST_INVERTER_MONITORING_LOADING,
        payload: null
    };
}

const chartSpecificYieldAction = response => {
    return {
        type: CONSTANTS.CHART_SPECIFIC_YIELD,
        payload: response.data
    };
}

const chartSpecificYieldActionLoading = () => {
    return {
        type: CONSTANTS.CHART_SPECIFIC_YIELD_LOADING,
        payload: null
    };
}


const chartDailyIODiagramAction = response => {
    return {
        type: CONSTANTS.CHART_DAILY_IO_DIAGRAM,
        payload: response.data
    };
}

const chartDailyIODiagramActionLoading = () => {
    return {
        type: CONSTANTS.CHART_DAILY_IO_DIAGRAM_LOADING,
        payload: null
    };
}

const chartPerformanceRatioTrackingAction = response => {
    return {
        type: CONSTANTS.CHART_PERFORMANCE_RATIO_TRACKING,
        payload: response.data
    };
}

const chartPerformanceRatioTrackingLoading = () => {
    return {
        type: CONSTANTS.CHART_PERFORMANCE_RATIO_TRACKING_LOADING,
        payload: null
    };
}

const chartActualProductionVsBudgetProductionAction = response => {
    return {
        type: CONSTANTS.CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION,
        payload: response.data
    };
}

const chartActualProductionVsBudgetProductionLoading = () => {
    return {
        type: CONSTANTS.CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION_LOADING,
        payload: null
    };
}

const chartCarbonOffsetAction = response => {
    return {
        type: CONSTANTS.CHART_CARBON_OFFSET,
        payload: response.data
    };
}

const chartCarbonOffsetLoading = () => {
    return {
        type: CONSTANTS.CHART_CARBON_OFFSET_LOADING,
        payload: null
    };
}

const gaugesAssetPerformanceAction = response => {
    return {
        type: CONSTANTS.GAUGES_ASSET_PERFORMANCE,
        payload: response.data
    };
}

const gaugesAssetPerformanceLoading = () => {
    return {
        type: CONSTANTS.GAUGES_ASSET_PERFORMANCE_LOADING,
        payload: null
    };
}

const inverterPerformanceAction = response => {
    return {
        type: CONSTANTS.LIST_INVERTER_PERFORMANCE,
        payload: response.data
    };
}

const inverterPerformanceActionLoading = () => {
    return {
        type: CONSTANTS.LIST_INVERTER_PERFORMANCE_LOADING,
        payload: null
    };
}

const mockupIV = {
    "dataType": DATA_TYPE.IV_CURVE,
    "from": "2022-08-09 19:43:05",
    "to": "2022-08-16 19:43:05",
    "groupBy": "date",
    "series": [{
        "name": "IV CURVE",
        "unit": "",
        "type": "spline",
        "data": [
            [0, 10.500],
            [0.5, 10.500],
            [1, 10.500],
            [1.5, 10.500],
            [2, 10.500],
            [2.5, 10.500],
            [3, 10.500],
            [3.5, 10.500],
            [4, 10.500],
            [4.5, 10.500],
            [5, 10.500],
            [5.5, 10.500],
            [6, 10.500],
            [6.5, 10.500],
            [7, 10.500],
            [7.5, 10.500],
            [8, 10.500],
            [8.5, 10.500],
            [9, 10.500],
            [9.5, 10.500],
            [10, 10.500],
            [10.5, 10.500],
            [11, 10.500],
            [11.5, 10.500],
            [12, 10.500],
            [12.5, 10.500],
            [13, 10.500],
            [13.5, 10.500],
            [14, 10.500],
            [14.5, 10.500],
            [15, 10.500],
            [15.5, 10.500],
            [16, 10.500],
            [16.5, 10.500],
            [17, 10.500],
            [17.5, 10.500],
            [18, 10.500],
            [18.5, 10.500],
            [19, 10.500],
            [19.5, 10.500],
            [20, 10.500],
            [20.5, 10.500],
            [21, 10.500],
            [21.5, 10.500],
            [22, 10.500],
            [22.5, 10.500],
            [23, 10.500],
            [23.5, 10.500],
            [24, 10.500],
            [24.5, 10.500],
            [25, 10.500],
            [25.5, 10.500],
            [26, 10.500],
            [26.5, 10.500],
            [27, 10.500],
            [27.5, 10.500],
            [28, 10.500],
            [28.5, 10.500],
            [29, 10.500],
            [29.5, 10.500],
            [30, 10.500],
            [30.5, 10.500],
            [31, 10.500],
            [31.5, 10.500],
            [32, 10.500],
            [32.5, 10.500],
            [33, 10.500],
            [33.5, 10.500],
            [34, 10.500],
            [34.5, 10.500],
            [35, 10.500],
            [35.5, 10.500],
            [36, 10.500],
            [36.5, 10.500],
            [37, 10.500],
            [37.5, 10.500],
            [38, 10.500],
            [38.5, 10.500],
            [39, 10.500],
            [39.5, 10.500],
            [40, 10.500],
            [40.5, 10.500],
            [41, 10.500],
            [41.5, 10.500],
            [42, 10.500],
            [42.5, 10.500],
            [43, 10.500],
            [43.5, 10.500],
            [44, 10.500],
            [44.5, 10.500],
            [45, 10.500],
            [45.5, 10.500],
            [46, 10.500],
            [46.5, 10.500],
            [47, 10.500],
            [47.5, 10.500],
            [48, 10.500],
            [48.5, 10.500],
            [49, 10.500],
            [49.5, 10.500],
            [50, 10.500],
            [50.5, 10.500],
            [51, 10.500],
            [51.5, 10.500],
            [52, 10.500],
            [52.5, 10.500],
            [53, 10.500],
            [53.5, 10.500],
            [54, 10.500],
            [54.5, 10.500],
            [55, 10.500],
            [55.5, 10.500],
            [56, 10.500],
            [56.5, 10.500],
            [57, 10.500],
            [57.5, 10.500],
            [58, 10.500],
            [58.5, 10.500],
            [59, 10.500],
            [59.5, 10.500],
            [60, 10.500],
            [60.5, 10.500],
            [61, 10.500],
            [61.5, 10.500],
            [62, 10.500],
            [62.5, 10.500],
            [63, 10.500],
            [63.5, 10.500],
            [64, 10.500],
            [64.5, 10.500],
            [65, 10.500],
            [65.5, 10.500],
            [66, 10.500],
            [66.5, 10.500],
            [67, 10.500],
            [67.5, 10.500],
            [68, 10.500],
            [68.5, 10.500],
            [69, 10.500],
            [69.5, 10.500],
            [70, 10.500],
            [70.5, 10.500],
            [71, 10.500],
            [71.5, 10.500],
            [72, 10.500],
            [72.5, 10.500],
            [73, 10.500],
            [73.5, 10.500],
            [74, 10.500],
            [74.5, 10.500],
            [75, 10.500],
            [75.5, 10.500],
            [76, 10.500],
            [76.5, 10.500],
            [77, 10.500],
            [77.5, 10.500],
            [78, 10.500],
            [78.5, 10.500],
            [79, 10.500],
            [79.5, 10.500],
            [80, 10.500],
            [80.5, 10.500],
            [81, 10.500],
            [81.5, 10.500],
            [82, 10.500],
            [82.5, 10.500],
            [83, 10.500],
            [83.5, 10.500],
            [84, 10.500],
            [84.5, 10.500],
            [85, 10.500],
            [85.5, 10.500],
            [86, 10.500],
            [86.5, 10.500],
            [87, 10.500],
            [87.5, 10.500],
            [88, 10.500],
            [88.5, 10.500],
            [89, 10.500],
            [89.5, 10.500],
            [90, 10.500],
            [90.5, 10.500],
            [91, 10.500],
            [91.5, 10.500],
            [92, 10.500],
            [92.5, 10.500],
            [93, 10.500],
            [93.5, 10.500],
            [94, 10.500],
            [94.5, 10.500],
            [95, 10.500],
            [95.5, 10.500],
            [96, 10.500],
            [96.5, 10.500],
            [97, 10.500],
            [97.5, 10.500],
            [98, 10.500],
            [98.5, 10.500],
            [99, 10.500],
            [99.5, 10.500],
            [100, 10.500],
            [100.5, 10.500],
            [101, 10.500],
            [101.5, 10.500],
            [102, 10.500],
            [102.5, 10.500],
            [103, 10.500],
            [103.5, 10.500],
            [104, 10.500],
            [104.5, 10.500],
            [105, 10.500],
            [105.5, 10.500],
            [106, 10.500],
            [106.5, 10.500],
            [107, 10.500],
            [107.5, 10.500],
            [108, 10.500],
            [108.5, 10.500],
            [109, 10.500],
            [109.5, 10.500],
            [110, 10.500],
            [110.5, 10.500],
            [111, 10.500],
            [111.5, 10.500],
            [112, 10.500],
            [112.5, 10.500],
            [113, 10.500],
            [113.5, 10.500],
            [114, 10.500],
            [114.5, 10.500],
            [115, 10.500],
            [115.5, 10.500],
            [116, 10.500],
            [116.5, 10.500],
            [117, 10.500],
            [117.5, 10.500],
            [118, 10.500],
            [118.5, 10.500],
            [119, 10.500],
            [119.5, 10.500],
            [120, 10.500],
            [120.5, 10.500],
            [121, 10.500],
            [121.5, 10.500],
            [122, 10.500],
            [122.5, 10.500],
            [123, 10.500],
            [123.5, 10.500],
            [124, 10.500],
            [124.5, 10.500],
            [125, 10.500],
            [125.5, 10.500],
            [126, 10.500],
            [126.5, 10.500],
            [127, 10.500],
            [127.5, 10.500],
            [128, 10.500],
            [128.5, 10.500],
            [129, 10.500],
            [129.5, 10.500],
            [130, 10.500],
            [130.5, 10.500],
            [131, 10.500],
            [131.5, 10.500],
            [132, 10.500],
            [132.5, 10.500],
            [133, 10.500],
            [133.5, 10.500],
            [134, 10.500],
            [134.5, 10.500],
            [135, 10.500],
            [135.5, 10.500],
            [136, 10.500],
            [136.5, 10.500],
            [137, 10.500],
            [137.5, 10.500],
            [138, 10.500],
            [138.5, 10.500],
            [139, 10.500],
            [139.5, 10.500],
            [140, 10.500],
            [140.5, 10.500],
            [141, 10.500],
            [141.5, 10.500],
            [142, 10.500],
            [142.5, 10.500],
            [143, 10.500],
            [143.5, 10.500],
            [144, 10.500],
            [144.5, 10.500],
            [145, 10.500],
            [145.5, 10.500],
            [146, 10.500],
            [146.5, 10.500],
            [147, 10.500],
            [147.5, 10.500],
            [148, 10.500],
            [148.5, 10.500],
            [149, 10.500],
            [149.5, 10.500],
            [150, 10.500],
            [150.5, 10.500],
            [151, 10.500],
            [151.5, 10.500],
            [152, 10.500],
            [152.5, 10.500],
            [153, 10.500],
            [153.5, 10.500],
            [154, 10.500],
            [154.5, 10.500],
            [155, 10.500],
            [155.5, 10.500],
            [156, 10.500],
            [156.5, 10.500],
            [157, 10.500],
            [157.5, 10.500],
            [158, 10.500],
            [158.5, 10.500],
            [159, 10.500],
            [159.5, 10.500],
            [160, 10.500],
            [160.5, 10.500],
            [161, 10.500],
            [161.5, 10.500],
            [162, 10.500],
            [162.5, 10.500],
            [163, 10.500],
            [163.5, 10.500],
            [164, 10.500],
            [164.5, 10.500],
            [165, 10.500],
            [165.5, 10.500],
            [166, 10.500],
            [166.5, 10.500],
            [167, 10.500],
            [167.5, 10.500],
            [168, 10.500],
            [168.5, 10.500],
            [169, 10.500],
            [169.5, 10.500],
            [170, 10.500],
            [170.5, 10.500],
            [171, 10.500],
            [171.5, 10.500],
            [172, 10.500],
            [172.5, 10.500],
            [173, 10.500],
            [173.5, 10.500],
            [174, 10.500],
            [174.5, 10.500],
            [175, 10.500],
            [175.5, 10.500],
            [176, 10.500],
            [176.5, 10.500],
            [177, 10.500],
            [177.5, 10.500],
            [178, 10.500],
            [178.5, 10.500],
            [179, 10.500],
            [179.5, 10.500],
            [180, 10.500],
            [180.5, 10.500],
            [181, 10.500],
            [181.5, 10.500],
            [182, 10.500],
            [182.5, 10.500],
            [183, 10.500],
            [183.5, 10.500],
            [184, 10.500],
            [184.5, 10.500],
            [185, 10.500],
            [185.5, 10.500],
            [186, 10.500],
            [186.5, 10.500],
            [187, 10.500],
            [187.5, 10.500],
            [188, 10.500],
            [188.5, 10.500],
            [189, 10.500],
            [189.5, 10.500],
            [190, 10.500],
            [190.5, 10.500],
            [191, 10.500],
            [191.5, 10.500],
            [192, 10.500],
            [192.5, 10.500],
            [193, 10.500],
            [193.5, 10.500],
            [194, 10.500],
            [194.5, 10.500],
            [195, 10.500],
            [195.5, 10.500],
            [196, 10.500],
            [196.5, 10.500],
            [197, 10.500],
            [197.5, 10.500],
            [198, 10.500],
            [198.5, 10.500],
            [199, 10.500],
            [199.5, 10.500],
            [200, 10.500],
            [200.5, 10.500],
            [201, 10.500],
            [201.5, 10.500],
            [202, 10.500],
            [202.5, 10.500],
            [203, 10.500],
            [203.5, 10.500],
            [204, 10.500],
            [204.5, 10.500],
            [205, 10.500],
            [205.5, 10.500],
            [206, 10.500],
            [206.5, 10.500],
            [207, 10.500],
            [207.5, 10.500],
            [208, 10.500],
            [208.5, 10.500],
            [209, 10.500],
            [209.5, 10.500],
            [210, 10.500],
            [210.5, 10.500],
            [211, 10.500],
            [211.5, 10.500],
            [212, 10.500],
            [212.5, 10.500],
            [213, 10.500],
            [213.5, 10.500],
            [214, 10.500],
            [214.5, 10.500],
            [215, 10.500],
            [215.5, 10.500],
            [216, 10.500],
            [216.5, 10.500],
            [217, 10.500],
            [217.5, 10.500],
            [218, 10.500],
            [218.5, 10.500],
            [219, 10.500],
            [219.5, 10.500],
            [220, 10.500],
            [220.5, 10.500],
            [221, 10.500],
            [221.5, 10.500],
            [222, 10.500],
            [222.5, 10.500],
            [223, 10.500],
            [223.5, 10.500],
            [224, 10.500],
            [224.5, 10.500],
            [225, 10.500],
            [225.5, 10.500],
            [226, 10.500],
            [226.5, 10.500],
            [227, 10.500],
            [227.5, 10.500],
            [228, 10.500],
            [228.5, 10.500],
            [229, 10.500],
            [229.5, 10.500],
            [230, 10.500],
            [230.5, 10.500],
            [231, 10.500],
            [231.5, 10.500],
            [232, 10.500],
            [232.5, 10.500],
            [233, 10.500],
            [233.5, 10.500],
            [234, 10.500],
            [234.5, 10.500],
            [235, 10.500],
            [235.5, 10.500],
            [236, 10.500],
            [236.5, 10.500],
            [237, 10.500],
            [237.5, 10.500],
            [238, 10.500],
            [238.5, 10.500],
            [239, 10.500],
            [239.5, 10.500],
            [240, 10.500],
            [240.5, 10.500],
            [241, 10.500],
            [241.5, 10.500],
            [242, 10.500],
            [242.5, 10.500],
            [243, 10.500],
            [243.5, 10.500],
            [244, 10.500],
            [244.5, 10.500],
            [245, 10.500],
            [245.5, 10.500],
            [246, 10.500],
            [246.5, 10.500],
            [247, 10.500],
            [247.5, 10.500],
            [248, 10.500],
            [248.5, 10.500],
            [249, 10.500],
            [249.5, 10.500],
            [250, 10.500],
            [250.5, 10.500],
            [251, 10.500],
            [251.5, 10.500],
            [252, 10.500],
            [252.5, 10.500],
            [253, 10.500],
            [253.5, 10.500],
            [254, 10.500],
            [254.5, 10.500],
            [255, 10.500],
            [255.5, 10.500],
            [256, 10.500],
            [256.5, 10.500],
            [257, 10.500],
            [257.5, 10.500],
            [258, 10.500],
            [258.5, 10.500],
            [259, 10.500],
            [259.5, 10.500],
            [260, 10.500],
            [260.5, 10.500],
            [261, 10.500],
            [261.5, 10.500],
            [262, 10.500],
            [262.5, 10.500],
            [263, 10.500],
            [263.5, 10.500],
            [264, 10.500],
            [264.5, 10.500],
            [265, 10.500],
            [265.5, 10.500],
            [266, 10.500],
            [266.5, 10.500],
            [267, 10.500],
            [267.5, 10.500],
            [268, 10.500],
            [268.5, 10.500],
            [269, 10.500],
            [269.5, 10.500],
            [270, 10.500],
            [270.5, 10.500],
            [271, 10.500],
            [271.5, 10.500],
            [272, 10.500],
            [272.5, 10.500],
            [273, 10.500],
            [273.5, 10.500],
            [274, 10.500],
            [274.5, 10.500],
            [275, 10.500],
            [275.5, 10.500],
            [276, 10.500],
            [276.5, 10.500],
            [277, 10.500],
            [277.5, 10.500],
            [278, 10.500],
            [278.5, 10.500],
            [279, 10.500],
            [279.5, 10.500],
            [280, 10.500],
            [280.5, 10.500],
            [281, 10.500],
            [281.5, 10.500],
            [282, 10.500],
            [282.5, 10.500],
            [283, 10.500],
            [283.5, 10.500],
            [284, 10.500],
            [284.5, 10.500],
            [285, 10.500],
            [285.5, 10.500],
            [286, 10.500],
            [286.5, 10.500],
            [287, 10.500],
            [287.5, 10.500],
            [288, 10.500],
            [288.5, 10.500],
            [289, 10.500],
            [289.5, 10.500],
            [290, 10.500],
            [290.5, 10.500],
            [291, 10.500],
            [291.5, 10.500],
            [292, 10.500],
            [292.5, 10.500],
            [293, 10.500],
            [293.5, 10.500],
            [294, 10.500],
            [294.5, 10.500],
            [295, 10.500],
            [295.5, 10.500],
            [296, 10.500],
            [296.5, 10.500],
            [297, 10.500],
            [297.5, 10.500],
            [298, 10.500],
            [298.5, 10.500],
            [299, 10.500],
            [299.5, 10.500],
            [300, 10.500],
            [300.5, 10.500],
            [301, 10.500],
            [301.5, 10.500],
            [302, 10.500],
            [302.5, 10.500],
            [303, 10.500],
            [303.5, 10.500],
            [304, 10.500],
            [304.5, 10.500],
            [305, 10.500],
            [305.5, 10.500],
            [306, 10.500],
            [306.5, 10.500],
            [307, 10.500],
            [307.5, 10.500],
            [308, 10.500],
            [308.5, 10.500],
            [309, 10.500],
            [309.5, 10.500],
            [310, 10.500],
            [310.5, 10.500],
            [311, 10.500],
            [311.5, 10.500],
            [312, 10.500],
            [312.5, 10.500],
            [313, 10.500],
            [313.5, 10.500],
            [314, 10.500],
            [314.5, 10.500],
            [315, 10.500],
            [315.5, 10.500],
            [316, 10.500],
            [316.5, 10.500],
            [317, 10.500],
            [317.5, 10.500],
            [318, 10.500],
            [318.5, 10.500],
            [319, 10.500],
            [319.5, 10.500],
            [320, 10.500],
            [320.5, 10.500],
            [321, 10.500],
            [321.5, 10.500],
            [322, 10.500],
            [322.5, 10.500],
            [323, 10.500],
            [323.5, 10.500],
            [324, 10.500],
            [324.5, 10.500],
            [325, 10.500],
            [325.5, 10.500],
            [326, 10.500],
            [326.5, 10.500],
            [327, 10.500],
            [327.5, 10.500],
            [328, 10.500],
            [328.5, 10.500],
            [329, 10.500],
            [329.5, 10.500],
            [330, 10.500],
            [330.5, 10.500],
            [331, 10.500],
            [331.5, 10.500],
            [332, 10.500],
            [332.5, 10.500],
            [333, 10.500],
            [333.5, 10.500],
            [334, 10.500],
            [334.5, 10.500],
            [335, 10.500],
            [335.5, 10.500],
            [336, 10.500],
            [336.5, 10.500],
            [337, 10.500],
            [337.5, 10.500],
            [338, 10.500],
            [338.5, 10.500],
            [339, 10.500],
            [339.5, 10.500],
            [340, 10.500],
            [340.5, 10.500],
            [341, 10.500],
            [341.5, 10.500],
            [342, 10.500],
            [342.5, 10.500],
            [343, 10.500],
            [343.5, 10.500],
            [344, 10.500],
            [344.5, 10.500],
            [345, 10.500],
            [345.5, 10.500],
            [346, 10.500],
            [346.5, 10.500],
            [347, 10.500],
            [347.5, 10.500],
            [348, 10.500],
            [348.5, 10.500],
            [349, 10.500],
            [349.5, 10.500],
            [350, 10.500],
            [350.5, 10.500],
            [351, 10.500],
            [351.5, 10.500],
            [352, 10.500],
            [352.5, 10.500],
            [353, 10.500],
            [353.5, 10.500],
            [354, 10.500],
            [354.5, 10.500],
            [355, 10.500],
            [355.5, 10.500],
            [356, 10.500],
            [356.5, 10.500],
            [357, 10.500],
            [357.5, 10.500],
            [358, 10.500],
            [358.5, 10.500],
            [359, 10.500],
            [359.5, 10.500],
            [360, 10.500],
            [360.5, 10.500],
            [361, 10.500],
            [361.5, 10.500],
            [362, 10.500],
            [362.5, 10.500],
            [363, 10.500],
            [363.5, 10.500],
            [364, 10.500],
            [364.5, 10.500],
            [365, 10.500],
            [365.5, 10.500],
            [366, 10.500],
            [366.5, 10.500],
            [367, 10.500],
            [367.5, 10.500],
            [368, 10.500],
            [368.5, 10.500],
            [369, 10.500],
            [369.5, 10.500],
            [370, 10.500],
            [370.5, 10.500],
            [371, 10.500],
            [371.5, 10.500],
            [372, 10.500],
            [372.5, 10.500],
            [373, 10.500],
            [373.5, 10.500],
            [374, 10.500],
            [374.5, 10.500],
            [375, 10.500],
            [375.5, 10.500],
            [376, 10.500],
            [376.5, 10.500],
            [377, 10.500],
            [377.5, 10.500],
            [378, 10.500],
            [378.5, 10.500],
            [379, 10.500],
            [379.5, 10.500],
            [380, 10.500],
            [380.5, 10.500],
            [381, 10.500],
            [381.5, 10.500],
            [382, 10.500],
            [382.5, 10.500],
            [383, 10.500],
            [383.5, 10.500],
            [384, 10.500],
            [384.5, 10.500],
            [385, 10.500],
            [385.5, 10.500],
            [386, 10.500],
            [386.5, 10.500],
            [387, 10.500],
            [387.5, 10.500],
            [388, 10.500],
            [388.5, 10.500],
            [389, 10.500],
            [389.5, 10.500],
            [390, 10.500],
            [390.5, 10.500],
            [391, 10.500],
            [391.5, 10.500],
            [392, 10.500],
            [392.5, 10.500],
            [393, 10.500],
            [393.5, 10.500],
            [394, 10.500],
            [394.5, 10.500],
            [395, 10.500],
            [395.5, 10.500],
            [396, 10.500],
            [396.5, 10.500],
            [397, 10.500],
            [397.5, 10.500],
            [398, 10.500],
            [398.5, 10.500],
            [399, 10.500],
            [399.5, 10.500],
            [400, 10.500],
            [400.5, 10.500],
            [401, 10.500],
            [401.5, 10.500],
            [402, 10.500],
            [402.5, 10.500],
            [403, 10.500],
            [403.5, 10.500],
            [404, 10.500],
            [404.5, 10.500],
            [405, 10.500],
            [405.5, 10.500],
            [406, 10.500],
            [406.5, 10.500],
            [407, 10.500],
            [407.5, 10.500],
            [408, 10.500],
            [408.5, 10.500],
            [409, 10.500],
            [409.5, 10.500],
            [410, 10.500],
            [410.5, 10.500],
            [411, 10.500],
            [411.5, 10.500],
            [412, 10.500],
            [412.5, 10.500],
            [413, 10.500],
            [413.5, 10.500],
            [414, 10.500],
            [414.5, 10.500],
            [415, 10.500],
            [415.5, 10.500],
            [416, 10.500],
            [416.5, 10.500],
            [417, 10.500],
            [417.5, 10.500],
            [418, 10.500],
            [418.5, 10.500],
            [419, 10.500],
            [419.5, 10.500],
            [420, 10.500],
            [420.5, 10.500],
            [421, 10.500],
            [421.5, 10.500],
            [422, 10.500],
            [422.5, 10.500],
            [423, 10.500],
            [423.5, 10.500],
            [424, 10.500],
            [424.5, 10.500],
            [425, 10.500],
            [425.5, 10.500],
            [426, 10.500],
            [426.5, 10.500],
            [427, 10.500],
            [427.5, 10.500],
            [428, 10.500],
            [428.5, 10.500],
            [429, 10.500],
            [429.5, 10.500],
            [430, 10.500],
            [430.5, 10.500],
            [431, 10.500],
            [431.5, 10.500],
            [432, 10.500],
            [432.5, 10.500],
            [433, 10.500],
            [433.5, 10.500],
            [434, 10.500],
            [434.5, 10.500],
            [435, 10.500],
            [435.5, 10.500],
            [436, 10.500],
            [436.5, 10.500],
            [437, 10.500],
            [437.5, 10.500],
            [438, 10.500],
            [438.5, 10.500],
            [439, 10.500],
            [439.5, 10.500],
            [440, 10.500],
            [440.5, 10.500],
            [441, 10.500],
            [441.5, 10.500],
            [442, 10.500],
            [442.5, 10.500],
            [443, 10.500],
            [443.5, 10.500],
            [444, 10.500],
            [444.5, 10.500],
            [445, 10.500],
            [445.5, 10.500],
            [446, 10.500],
            [446.5, 10.500],
            [447, 10.500],
            [447.5, 10.500],
            [448, 10.500],
            [448.5, 10.500],
            [449, 10.500],
            [449.5, 10.500],
            [450, 10.500],
            [450.5, 10.500],
            [451, 10.500],
            [451.5, 10.500],
            [452, 10.500],
            [452.5, 10.500],
            [453, 10.500],
            [453.5, 10.500],
            [454, 10.500],
            [454.5, 10.500],
            [455, 10.500],
            [455.5, 10.500],
            [456, 10.500],
            [456.5, 10.500],
            [457, 10.500],
            [457.5, 10.500],
            [458, 10.500],
            [458.5, 10.500],
            [459, 10.500],
            [459.5, 10.500],
            [460, 10.500],
            [460.5, 10.500],
            [461, 10.500],
            [461.5, 10.500],
            [462, 10.500],
            [462.5, 10.500],
            [463, 10.500],
            [463.5, 10.500],
            [464, 10.500],
            [464.5, 10.500],
            [465, 10.500],
            [465.5, 10.500],
            [466, 10.500],
            [466.5, 10.500],
            [467, 10.500],
            [467.5, 10.500],
            [468, 10.500],
            [468.5, 10.500],
            [469, 10.500],
            [469.5, 10.500],
            [470, 10.500],
            [470.5, 10.500],
            [471, 10.500],
            [471.5, 10.500],
            [472, 10.500],
            [472.5, 10.500],
            [473, 10.500],
            [473.5, 10.500],
            [474, 10.500],
            [474.5, 10.500],
            [475, 10.500],
            [475.5, 10.500],
            [476, 10.500],
            [476.5, 10.500],
            [477, 10.500],
            [477.5, 10.500],
            [478, 10.500],
            [478.5, 10.500],
            [479, 10.499],
            [479.5, 10.499],
            [480, 10.499],
            [480.5, 10.499],
            [481, 10.499],
            [481.5, 10.499],
            [482, 10.499],
            [482.5, 10.499],
            [483, 10.499],
            [483.5, 10.499],
            [484, 10.499],
            [484.5, 10.499],
            [485, 10.499],
            [485.5, 10.499],
            [486, 10.499],
            [486.5, 10.499],
            [487, 10.499],
            [487.5, 10.499],
            [488, 10.499],
            [488.5, 10.499],
            [489, 10.499],
            [489.5, 10.499],
            [490, 10.499],
            [490.5, 10.499],
            [491, 10.499],
            [491.5, 10.499],
            [492, 10.499],
            [492.5, 10.499],
            [493, 10.499],
            [493.5, 10.499],
            [494, 10.499],
            [494.5, 10.499],
            [495, 10.499],
            [495.5, 10.499],
            [496, 10.499],
            [496.5, 10.499],
            [497, 10.499],
            [497.5, 10.499],
            [498, 10.499],
            [498.5, 10.499],
            [499, 10.499],
            [499.5, 10.499],
            [500, 10.499],
            [500.5, 10.499],
            [501, 10.498],
            [501.5, 10.498],
            [502, 10.498],
            [502.5, 10.498],
            [503, 10.498],
            [503.5, 10.498],
            [504, 10.498],
            [504.5, 10.498],
            [505, 10.498],
            [505.5, 10.498],
            [506, 10.498],
            [506.5, 10.498],
            [507, 10.498],
            [507.5, 10.498],
            [508, 10.498],
            [508.5, 10.498],
            [509, 10.498],
            [509.5, 10.498],
            [510, 10.498],
            [510.5, 10.498],
            [511, 10.498],
            [511.5, 10.497],
            [512, 10.497],
            [512.5, 10.497],
            [513, 10.497],
            [513.5, 10.497],
            [514, 10.497],
            [514.5, 10.497],
            [515, 10.497],
            [515.5, 10.497],
            [516, 10.497],
            [516.5, 10.497],
            [517, 10.497],
            [517.5, 10.497],
            [518, 10.496],
            [518.5, 10.496],
            [519, 10.496],
            [519.5, 10.496],
            [520, 10.496],
            [520.5, 10.496],
            [521, 10.496],
            [521.5, 10.496],
            [522, 10.496],
            [522.5, 10.496],
            [523, 10.495],
            [523.5, 10.495],
            [524, 10.495],
            [524.5, 10.495],
            [525, 10.495],
            [525.5, 10.495],
            [526, 10.495],
            [526.5, 10.495],
            [527, 10.494],
            [527.5, 10.494],
            [528, 10.494],
            [528.5, 10.494],
            [529, 10.494],
            [529.5, 10.494],
            [530, 10.494],
            [530.5, 10.493],
            [531, 10.493],
            [531.5, 10.493],
            [532, 10.493],
            [532.5, 10.493],
            [533, 10.493],
            [533.5, 10.492],
            [534, 10.492],
            [534.5, 10.492],
            [535, 10.492],
            [535.5, 10.492],
            [536, 10.491],
            [536.5, 10.491],
            [537, 10.491],
            [537.5, 10.491],
            [538, 10.490],
            [538.5, 10.490],
            [539, 10.490],
            [539.5, 10.490],
            [540, 10.489],
            [540.5, 10.489],
            [541, 10.489],
            [541.5, 10.489],
            [542, 10.488],
            [542.5, 10.488],
            [543, 10.488],
            [543.5, 10.487],
            [544, 10.487],
            [544.5, 10.487],
            [545, 10.486],
            [545.5, 10.486],
            [546, 10.486],
            [546.5, 10.485],
            [547, 10.485],
            [547.5, 10.485],
            [548, 10.484],
            [548.5, 10.484],
            [549, 10.483],
            [549.5, 10.483],
            [550, 10.482],
            [550.5, 10.482],
            [551, 10.482],
            [551.5, 10.481],
            [552, 10.481],
            [552.5, 10.480],
            [553, 10.480],
            [553.5, 10.479],
            [554, 10.479],
            [554.5, 10.478],
            [555, 10.477],
            [555.5, 10.477],
            [556, 10.476],
            [556.5, 10.476],
            [557, 10.475],
            [557.5, 10.474],
            [558, 10.474],
            [558.5, 10.473],
            [559, 10.472],
            [559.5, 10.472],
            [560, 10.471],
            [560.5, 10.470],
            [561, 10.470],
            [561.5, 10.469],
            [562, 10.468],
            [562.5, 10.467],
            [563, 10.466],
            [563.5, 10.466],
            [564, 10.465],
            [564.5, 10.464],
            [565, 10.463],
            [565.5, 10.462],
            [566, 10.461],
            [566.5, 10.460],
            [567, 10.459],
            [567.5, 10.458],
            [568, 10.457],
            [568.5, 10.456],
            [569, 10.455],
            [569.5, 10.453],
            [570, 10.452],
            [570.5, 10.451],
            [571, 10.450],
            [571.5, 10.449],
            [572, 10.447],
            [572.5, 10.446],
            [573, 10.445],
            [573.5, 10.443],
            [574, 10.442],
            [574.5, 10.440],
            [575, 10.439],
            [575.5, 10.437],
            [576, 10.436],
            [576.5, 10.434],
            [577, 10.432],
            [577.5, 10.431],
            [578, 10.429],
            [578.5, 10.427],
            [579, 10.425],
            [579.5, 10.423],
            [580, 10.421],
            [580.5, 10.419],
            [581, 10.417],
            [581.5, 10.415],
            [582, 10.413],
            [582.5, 10.411],
            [583, 10.409],
            [583.5, 10.406],
            [584, 10.404],
            [584.5, 10.402],
            [585, 10.399],
            [585.5, 10.396],
            [586, 10.394],
            [586.5, 10.391],
            [587, 10.388],
            [587.5, 10.386],
            [588, 10.383],
            [588.5, 10.380],
            [589, 10.377],
            [589.5, 10.374],
            [590, 10.370],
            [590.5, 10.367],
            [591, 10.364],
            [591.5, 10.360],
            [592, 10.357],
            [592.5, 10.353],
            [593, 10.349],
            [593.5, 10.346],
            [594, 10.342],
            [594.5, 10.338],
            [595, 10.334],
            [595.5, 10.329],
            [596, 10.325],
            [596.5, 10.321],
            [597, 10.316],
            [597.5, 10.311],
            [598, 10.307],
            [598.5, 10.302],
            [599, 10.297],
            [599.5, 10.292],
            [600, 10.286],
            [600.5, 10.281],
            [601, 10.275],
            [601.5, 10.270],
            [602, 10.264],
            [602.5, 10.258],
            [603, 10.252],
            [603.5, 10.245],
            [604, 10.239],
            [604.5, 10.232],
            [605, 10.226],
            [605.5, 10.219],
            [606, 10.211],
            [606.5, 10.204],
            [607, 10.197],
            [607.5, 10.189],
            [608, 10.181],
            [608.5, 10.173],
            [609, 10.165],
            [609.5, 10.156],
            [610, 10.148],
            [610.5, 10.139],
            [611, 10.130],
            [611.5, 10.120],
            [612, 10.111],
            [612.5, 10.101],
            [613, 10.091],
            [613.5, 10.080],
            [614, 10.070],
            [614.5, 10.059],
            [615, 10.048],
            [615.5, 10.036],
            [616, 10.024],
            [616.5, 10.012],
            [617, 10.000],
            [617.5, 9.987],
            [618, 9.974],
            [618.5, 9.961],
            [619, 9.947],
            [619.5, 9.933],
            [620, 9.919],
            [620.5, 9.904],
            [621, 9.889],
            [621.5, 9.874],
            [622, 9.858],
            [622.5, 9.842],
            [623, 9.825],
            [623.5, 9.808],
            [624, 9.790],
            [624.5, 9.772],
            [625, 9.754],
            [625.5, 9.735],
            [626, 9.716],
            [626.5, 9.696],
            [627, 9.676],
            [627.5, 9.655],
            [628, 9.633],
            [628.5, 9.611],
            [629, 9.589],
            [629.5, 9.566],
            [630, 9.542],
            [630.5, 9.518],
            [631, 9.493],
            [631.5, 9.468],
            [632, 9.441],
            [632.5, 9.415],
            [633, 9.387],
            [633.5, 9.359],
            [634, 9.330],
            [634.5, 9.300],
            [635, 9.270],
            [635.5, 9.239],
            [636, 9.207],
            [636.5, 9.174],
            [637, 9.141],
            [637.5, 9.106],
            [638, 9.071],
            [638.5, 9.035],
            [639, 8.998],
            [639.5, 8.960],
            [640, 8.921],
            [640.5, 8.881],
            [641, 8.840],
            [641.5, 8.798],
            [642, 8.755],
            [642.5, 8.710],
            [643, 8.665],
            [643.5, 8.619],
            [644, 8.571],
            [644.5, 8.522],
            [645, 8.472],
            [645.5, 8.421],
            [646, 8.368],
            [646.5, 8.314],
            [647, 8.259],
            [647.5, 8.202],
            [648, 8.144],
            [648.5, 8.084],
            [649, 8.023],
            [649.5, 7.961],
            [650, 7.896],
            [650.5, 7.830],
            [651, 7.763],
            [651.5, 7.693],
            [652, 7.622],
            [652.5, 7.550],
            [653, 7.475],
            [653.5, 7.398],
            [654, 7.320],
            [654.5, 7.239],
            [655, 7.157],
            [655.5, 7.072],
            [656, 6.985],
            [656.5, 6.896],
            [657, 6.805],
            [657.5, 6.712],
            [658, 6.616],
            [658.5, 6.517],
            [659, 6.416],
            [659.5, 6.313],
            [660, 6.207],
            [660.5, 6.098],
            [661, 5.987],
            [661.5, 5.873],
            [662, 5.756],
            [662.5, 5.636],
            [663, 5.512],
            [663.5, 5.386],
            [664, 5.257],
            [664.5, 5.124],
            [665, 4.988],
            [665.5, 4.848],
            [666, 4.705],
            [666.5, 4.559],
            [667, 4.408],
            [667.5, 4.254],
            [668, 4.096],
            [668.5, 3.934],
            [669, 3.767],
            [669.5, 3.597],
            [670, 3.422],
            [670.5, 3.243],
            [671, 3.059],
            [671.5, 2.871],
            [672, 2.678],
            [672.5, 2.480],
            [673, 2.277],
            [673.5, 2.069],
            [674, 1.855],
            [674.5, 1.636],
            [675, 1.412],
            [675.5, 1.182],
            [676, 0.946],
            [676.5, 0.704],
            [677, 0.456],
            [677.5, 0.202]
        ]
    }]
}