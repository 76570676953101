import React, { Component } from 'react';
import { ToolboxControl, AntCard, TagSwitch } from "@layouts";
import { connect } from "react-redux";
import { ControlOutlined } from "@ant-design/icons";
import { Divider, Switch } from "antd";
import helpers from "@ultis/helpers";

class CustomComponent extends Component {
    render = () => {
        const { common } = this.props;
        const parentElementId = "plant-control-status";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <ControlOutlined/> Control Status
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            updateAt={helpers.getNow()}
                        />
                    </div>
                }
            >
                <div className="card-custom-body card-control-status height-350">
                    <div className="group-control">
                        <div className="group-title">
                            <div className="title-main">General</div>
                        </div>
                        <div className="group-value">
                            <div className="group-item">
                                <div className="label">
                                    <TagSwitch true/>
                                </div>
                                <div className="value">
                                    Zero-Export
                                </div>
                            </div>
                            {/*<div className="group-item">
                                <div className="label">
                                    <TagSwitch/>
                                </div>
                                <div className="value">
                                    Zero-Export 2
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    <Divider className="divider"/>
                    <div className="group-control">
                        <div className="group-title">
                            <div className="title-main">Group of Control Point A</div>
                            <div className="title-extra">
                                <div className="group-item">
                                    <div className="label">
                                        <TagSwitch true/>
                                    </div>
                                    <div className="value">
                                        Remote Allow
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group-value">
                            <div className="group-item">
                                <div className="label">
                                    <Switch/>
                                </div>
                                <div className="value">
                                    Control Point 1
                                </div>
                            </div>
                            <div className="group-item">
                                <div className="label">
                                    <Switch defaultChecked/>
                                </div>
                                <div className="value">
                                    Control Point 2
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider className="divider"/>
                    <div className="group-control">
                        <div className="group-title">
                            <div className="title-main">Group of Control Point A</div>
                            <div className="title-extra">
                                <div className="group-item">
                                    <div className="label">
                                        <TagSwitch/>
                                    </div>
                                    <div className="value">
                                        Remote Allow
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group-value">
                            <div className="group-item">
                                <div className="label">
                                    <Switch disabled/>
                                </div>
                                <div className="value">
                                    Control Point 1
                                </div>
                            </div>
                            <div className="group-item">
                                <div className="label">
                                    <Switch defaultChecked disabled/>
                                </div>
                                <div className="value">
                                    Control Point 2
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AntCard>
        )
    }

}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);