import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import historyCommon from "./historyCommon";
import { reducer as CrudReducer } from "./crud/reducer"
import { reducer as CommonReducer } from "@features/Common/redux/reducer"
import { reducer as AuthReducer } from "@features/Auth/redux/reducer"
import { reducer as SiteReducer } from "@features/Site/redux/reducer"
import { reducer as PlantReducer } from "@features/Plant/redux/reducer"
import { reducer as EventReducer } from "@features/Event/redux/reducer"
import { reducer as DeviceReducer } from "@features/Device/redux/reducer"
import { reducer as AnalysisReducer } from "@features/Tools/Analysis/redux/reducer"
import { reducer as ReportReducer } from "@features/Tools/Report/redux/reducer"
import { reducer as TechnicalMonitoringReducer } from "@features/TechnicalMonitoring/redux/reducer"
import { reducer as AssetPerformanceReducer } from "@features/AssetPerformance/redux/reducer"
import { reducer as BillingReducer } from "@features/Billing/redux/reducer"
import { reducer as TicketsManagementReducer } from "@features/TicketsManagement/redux/reducer"
import { reducer as PortfolioConfigurationsReducer } from "@features/Configurations/PortfolioConfigurations/redux/reducer"
import { reducer as BillingConfigurationsReducer } from "@features/Configurations/BillingConfigurations/redux/reducer"
import { reducer as MonitoringRulesReducer } from "@features/Configurations/MonitoringRules/redux/reducer"
import { reducer as socketIOReducer } from "./socketIO/reducer"

import { i18nextReducer } from 'i18next-redux-languagedetector';

const reducerMap = {
    i18next             : i18nextReducer,
    router              : connectRouter(historyCommon),
    socketIO            : socketIOReducer,
    common              : CommonReducer,
    crud                : CrudReducer,
    auth                : AuthReducer,
    site                : SiteReducer,
    plant               : PlantReducer,
    event               : EventReducer,
    device              : DeviceReducer,
    analysis            : AnalysisReducer,
    report              : ReportReducer,
    technicalMonitoring : TechnicalMonitoringReducer,
    assetPerformance    : AssetPerformanceReducer,
    billing             : BillingReducer,
    ticketsManagement   : TicketsManagementReducer,
    portfolioConfigs    : PortfolioConfigurationsReducer,
    billingConfigs      : BillingConfigurationsReducer,
    monitoringRules     : MonitoringRulesReducer,
};

export default combineReducers(reducerMap);
