import React, { Component } from 'react';
import { Row, Col } from 'antd';
import * as Components from "../../components";

class President extends Component {
    render = () => {
        const {
            reportType,
            downloadRequest,
            onClickDownload,
            onChangeReportType,
            onChangeTimePeriod,
            onChangeFileType,
            onChangeTimeRanges,
        } = this.props;

        return (
            <div className="features feature-home">
                <h1 className="page-title">
                    Report
                </h1>
                <Row gutter={{ xs: 8, sm: 12, md: 16 }}>
                    <Col xs={12} xl={8}>
                        <Components.ReportListComponent
                            onChangeReportType={onChangeReportType}
                        />
                    </Col>
                    <Col xs={12} xl={16}>
                        <Components.ReportDetailComponent
                            reportType={reportType}
                            downloadRequest={downloadRequest}
                            onClickDownload={onClickDownload}
                            onChangeTimePeriod={onChangeTimePeriod}
                            onChangeFileType={onChangeFileType}
                            onChangeTimeRanges={onChangeTimeRanges}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default President;