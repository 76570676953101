import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { renderLabel, TIMEZONE, tooltipDateFormat } from "../index";

class CustomComponent extends Component {
    render() {
        const { options } = this.props;

        // Init chart data
        const { series, groupBy } = options;

        // First line
        const seriesFirst = series[0] ?? {};
        const dataFirst = seriesFirst.data ?? [];
        const typeFirst = seriesFirst.type ?? '';
        const unitFirst = seriesFirst.unit ?? '';
        //const typeFirst   = "column";
        //const unitFirst   = "kWh";

        // Second line
        const seriesSecond = series[1] ?? {}
        const dataSecond = seriesSecond.data ?? [];
        const typeSecond = seriesSecond.type ?? '';
        const unitSecond = seriesSecond.unit ?? '';
        //const unitSecond   = "kWh";

        return (
            <HighchartsReact
                containerProps={{ className: 'highcharts-react' }}
                highcharts={Highcharts}
                options={{
                    "chart": {
                        "zoomType": "xy"
                    },
                    "title": {
                        "text": "",
                    },
                    "xAxis": [
                        {
                            "type": "datetime",
                            "labels": renderLabel(groupBy)
                        }
                    ],
                    "yAxis": [
                        // First line
                        {
                            "min": 0,
                            "labels": {
                                "format": `{value} ${unitFirst}`,
                                "style": {
                                    "color": "#666666"
                                }
                            },
                            "title": {
                                "text": "",
                            },
                        },
                    ],
                    "tooltip": {
                        "xDateFormat": tooltipDateFormat(groupBy, TIMEZONE),
                        "shared": true
                    },
                    "legend": {},
                    "series": [
                        {
                            "marker": {
                                "enabled": true,
                                "symbol": "square"
                            },
                            //"dashStyle": "ShortDot",
                            "zIndex": 4,
                            "lineWidth": 1,
                            "states": {
                                "hover": {
                                    "enabled": true,
                                    "lineWidth": 2
                                }
                            },
                            "type": typeFirst,
                            "tooltip": { "valueSuffix": ' ' + unitFirst },
                            "name": `AC Energy (${unitFirst})`,
                            "data": dataFirst,
                        },
                        {
                            "marker": {
                                "enabled": true,
                                "symbol": "square"
                            },
                            //"dashStyle": "ShortDot",
                            "zIndex": 4,
                            "lineWidth": 1,
                            "states": {
                                "hover": {
                                    "enabled": true,
                                    "lineWidth": 2
                                }
                            },
                            "type": typeSecond,
                            "color": "rgba(0, 0, 0, 0.65)",
                            "tooltip": { "valueSuffix": ' ' + unitSecond },
                            "name": `Budget Production (${unitSecond})`,
                            "data": dataSecond,
                        },
                    ],
                    "plotOptions": {
                        "series": {
                            "animation": false,
                            "maxPointWidth": 100
                        }
                    }
                }}
            />
        )
    }
}

export default CustomComponent;