import React, { Component } from 'react';
import { Link } from "react-router-dom";

class President extends Component {
    render = () => {
        return (
            <div className="home-page-wrapper footer-wrapper">
                <div className="footer">

                </div>
            </div>
        )
    }
}

export default President;