import React, { Component } from 'react';
import { connect } from "react-redux";
import { AntCard, TagInverterStatus, ToolboxControl } from "@layouts";
import { UnorderedListOutlined, ImportOutlined, InsertRowBelowOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Table } from 'antd';
import { findLocation } from "../../redux";
import { fetchTableEvent } from "@features/Event/redux";

class CustomComponent extends Component {
    props = this['props'];

    handleClickPlant = e => {
        const siteId = e.currentTarget.dataset.siteId;
        const plantId = e.currentTarget.dataset.plantId;
        const { plants, zoom } = this.props.overview;

        let coordinatesList = [];
        plants.forEach(item => {
            if (item.id.toString() === plantId.toString()) {
                coordinatesList.push(item)
            }
        });

        this.props.findLocation(coordinatesList, zoom);
        this.props.fetchTableEvent({
            siteId: siteId,
            plantId: plantId,
        });
    }

    handleClickSite = e => {
        const siteId = e.currentTarget.dataset.siteId;
        const { plants, zoom } = this.props.overview;

        let coordinatesList = [];
        plants.forEach(item => {
            if (item.siteId.toString() === siteId.toString()) {
                coordinatesList.push(item);
            }
        });
        this.props.findLocation(coordinatesList, zoom);
        this.props.fetchTableEvent({
            siteId: siteId,
        });
    }

    render = () => {
        const { common, fetchPlants, isDisplaySite, overview } = this.props;

        const parentElementId = "site-plant-list";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        const { loading, total, powerValue, powerUnit, plants } = overview;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <UnorderedListOutlined/> Plants
                            <i><span> - {total} Plants - {powerValue} {powerUnit}</span></i>
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            handleReload={fetchPlants}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height height-350">
                    <Table
                        loading={loading}
                        columns={this.renderColumns(isDisplaySite)}
                        dataSource={plants}
                        pagination={false}
                        rowKey="id"
                    >
                    </Table>
                </div>
            </AntCard>
        )
    }

    renderColumns = isDisplaySite => {
        let baseColumns = [
            {
                ellipsis: true,
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (value) => <TagInverterStatus level={value}/>,
                align: "center",
                width: 80,
            },
            {
                title: 'Plant',
                dataIndex: 'name',
                render: (text, item) => <div>
                    <InsertRowBelowOutlined/>
                    <span
                        className="highlight"
                        data-domain-id={"1"}
                        data-site-id={item.siteId}
                        data-plant-id={item.id}
                        onClick={this.handleClickPlant}> {text}</span>
                    <Link to={`/technical-monitoring/plant/${item.id}`}> <ImportOutlined
                        className="transform-180"/></Link>
                </div>,
            },
            {
                ellipsis: true,
                title: 'Capacity (kW)',
                dataIndex: 'powerValue',
                key: 'powerValue',
                width: 105,
                align: "center"
            },
            {
                ellipsis: true,
                title: 'Commissioning on',
                dataIndex: 'commissioningOn',
                key: 'commissioningOn',
                width: 130,
                align: "center"
            },
        ];

        if (isDisplaySite) {
            baseColumns.push(
                {
                    title: 'Site',
                    dataIndex: 'siteName',
                    render: (text, item) =>
                        <div>
                                    <span
                                        className="highlight"
                                        data-domain-id={"1"}
                                        data-site-id={item.siteId} onClick={this.handleClickSite}>{text}</span>
                            <Link to={`/technical-monitoring/site/${item.siteId}`}> <ImportOutlined
                                className="transform-180"/></Link>
                        </div>,
                    /*sorter   : {
                        compare : (a, b) => a.siteName.toString().localeCompare(b.siteName.toString()),
                        multiple: 3,
                    },*/
                },
            )
        }
        return baseColumns;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        findLocation: (coordinatesList, zoom) => {
            dispatch(findLocation(coordinatesList, zoom));
        },
        fetchTableEvent: params => {
            dispatch(fetchTableEvent(params));
        },
    };
}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomComponent);