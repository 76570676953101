// Initial state is the place you define all initial values for the Redux store of the feature.

const initialState = {
    overview: {
        loading            : false,
        total              : 0,
        powerValue         : 0,
        powerUnit          : '',
        plants             : [],
        coordinatesList    : [],
        coordinatesSelected: [],
        zoom               : 4,
    },

    events: {
        list  : {
            data      : [],
            pagination: {
                current : 1,
                pageSize: 25,
                total   : 0,
            },
            loading   : false,
        },
        detail: {
            errors : {},
            data   : {},
            loading: false,
            id     : null,
            isFound: true,

            update: {
                modalVisible: false,
                loading     : false,
            },
        },

        delete: {
            errors      : {},
            modalVisible: false,
            loading     : false,
            isDeleted   : false,
        },
    },

    ticket: {
        create: {
            errors : {},
            data   : {},
            loading: false,
        },
    },
};

export default initialState;