import React, { Component } from 'react';
import { Typography } from 'antd';
import { DesktopOutlined } from "@ant-design/icons";
import { AntCard, ToolboxControl, Loading } from "@layouts";
import { connect } from "react-redux";

class CustomComponent extends Component {
    props = this['props'];

    render = () => {
        const { common } = this.props;
        const parentElementId = "plant-billing";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Data current billing
        const { currentBilling } = this.props.billing;
        const { loading, data } = currentBilling;
        const { from, to, lastUpdate, listTimeSlotData, totalPriceNoVAT, totalPricePaymentNoVAT, priceVAT, priceDiscount, totalPricePayment } = data;

        const dateFrom = from ?? "-";
        const dateTo = to ?? "-";
        const lastUpdated = lastUpdate ?? "-";
        const listUsed = listTimeSlotData ?? [];
        const totalConsumption = data.totalConsumption ?? 0;
        const totalPrice = totalPriceNoVAT ?? 0;
        // const priceDiscount = data.priceDiscount ?? 0;
        const priceDiscounting = priceDiscount ?? 0;
        const totalPricePaymentTo = totalPricePaymentNoVAT ?? 0;
        const taxPrice = priceVAT ?? 0;
        const totalPricePaymentInTax = totalPricePayment ?? 0;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DesktopOutlined/> Billing
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                        />
                    </div>
                }
            >
                <div className="card-custom-body card-billing custom-blocked">
                    {loading ? <Loading overwrite/> : null}
                    <div className="text-center">
                        <Typography.Title level={4}>
                            KỲ HIỆN TẠI
                        </Typography.Title>
                        <div>
                            {dateFrom} đến {dateTo} (Cập nhật lần cuối {lastUpdated})
                        </div>
                    </div>
                    <CustomTable>
                        <CustomRow>
                            <CustomTHead>Khung giờ mua điện</CustomTHead>
                            <CustomTHead align={"right"}>Đơn giá (đồng/kWh)</CustomTHead>
                            <CustomTHead align={"right"}>Hệ số nhân</CustomTHead>
                            <CustomTHead align={"right"}>Chỉ số cũ</CustomTHead>
                            <CustomTHead align={"right"}>Chỉ số mới</CustomTHead>
                            <CustomTHead align={"right"}>Điện tiêu thụ (kWh)</CustomTHead>
                            <CustomTHead align={"right"}>Thành tiền (đồng)</CustomTHead>
                        </CustomRow>
                        {listUsed.map((item, idx) => {
                            return (
                                <CustomRow key={idx}>
                                    <CustomTData>{item.name ?? ""}</CustomTData>
                                    <CustomTData align={"right"}>{item.unitPrice ?? 0}</CustomTData>
                                    <CustomTData align={"right"}>{item.constant ?? "-"}</CustomTData>
                                    <CustomTData align={"right"}>{item.oldKWhNumber ?? 0}</CustomTData>
                                    <CustomTData align={"right"}>{item.newKWhNumber ?? 0}</CustomTData>
                                    <CustomTData align={"right"}>{item.consumption ?? 0}</CustomTData>
                                    <CustomTData align={"right"}>{item.price ?? 0}</CustomTData>
                                </CustomRow>
                            )
                        })}
                        <CustomRow>
                            <CustomTData span={5}>
                                Tổng điện năng tiêu thụ (kWh)
                            </CustomTData>
                            <CustomTData align={"right"}>{totalConsumption}</CustomTData>
                            <CustomTData></CustomTData>
                        </CustomRow>
                        <CustomRow>
                            <CustomTData span={6}>
                                Tổng tiền điện chưa thuế (đồng)
                            </CustomTData>
                            <CustomTData align={"right"}>{totalPrice}</CustomTData>
                        </CustomRow>
                        <CustomRow>
                            <CustomTData span={6}>
                                Giảm trừ chiết khấu <b className="text-success">10%</b> (đồng)
                            </CustomTData>
                            <CustomTData align={"right"}>
                                <span className="text-success">({priceDiscounting})</span>
                            </CustomTData>
                        </CustomRow>
                        <CustomRow>
                            <CustomTData span={6}>
                                Giá trị thanh toán chưa bao gồm thuế VAT (đồng)
                            </CustomTData>
                            <CustomTData align={"right"}>{totalPricePaymentTo}</CustomTData>
                        </CustomRow>
                        <CustomRow>
                            <CustomTData span={6}>
                                Thuế GTGT <b>8%</b> (đồng)
                            </CustomTData>
                            <CustomTData align={"right"}>{taxPrice}</CustomTData>
                        </CustomRow>
                        <CustomRow>
                            <CustomTData span={6}>
                                Tổng cộng tiền thanh toán (đồng)
                            </CustomTData>
                            <CustomTData align={"right"}>
                                <span className="text-warning">{totalPricePaymentInTax}</span>
                            </CustomTData>
                        </CustomRow>
                    </CustomTable>
                </div>
            </AntCard>
        )
    }
}

const mapStateToProps = state => {
    return {
        common: state.common,
        billing: state.billing,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);

const CustomTable = props => {
    return (
        <div className="ant-descriptions ant-descriptions-small ant-descriptions-bordered">
            <div className="ant-descriptions-view">
                <table>
                    <tbody>
                    {props.children}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const CustomRow = props => {
    return (
        <tr className="ant-descriptions-row">
            {props.children}
        </tr>
    )
}

const CustomTHead = props => {
    let { span, align } = props;
    span = span ?? 1;
    align = align ?? "left";
    return (
        <td className="ant-descriptions-item-label" colSpan={span}
            style={{
                textAlign: align
            }}
        >
            <span>{props.children}</span>
        </td>
    )
}

const CustomTData = props => {
    let { span, align } = props;
    span = span ?? 1;
    align = align ?? "left";
    return (
        <td className="ant-descriptions-item-content" colSpan={span}
            style={{
                textAlign: align
            }}
        >
            <span>{props.children}</span>
        </td>
    )
}