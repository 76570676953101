import initialState from "./initialState";
import * as CONSTANTS from './constants'
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export const reducer = (state = initialState, action) => {
    let payload = action.payload;
    let groupBy = null;
    let series = [];

    switch (action.type) {
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        /**
         * Plant logic
         */
        case CONSTANTS.PLANT_DETAIL:
            payload = payload ?? {}
            let detail = payload.data ?? {}
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    errors: detail.errors ?? [],
                    data: detail,
                    id: detail.id,
                    isFound: !!detail.id
                },
            };
        case CONSTANTS.PLANT_DETAIL_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                }
            }

        /**
         * Plant meta data
         */
        case CONSTANTS.CHART_ACTUAL_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    actualProductionIrradiation: {
                        ...state.meta.actualProductionIrradiation,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_ACTUAL:
            if (payload) {
                groupBy = payload.groupBy ?? null
                series = payload.series ?? []
            }
            return {
                ...state,
                meta: {
                    ...state.meta,
                    actualProductionIrradiation: {
                        ...state.meta.actualProductionIrradiation,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series
                        }
                    }
                }
            }

        case CONSTANTS.GAUGES_TECHNICAL_MONITORING_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    gaugesMonitoring: {
                        ...state.meta.gaugesMonitoring,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.GAUGES_TECHNICAL_MONITORING:
            payload = payload ?? {}
            return {
                ...state,
                meta: {
                    ...state.meta,
                    gaugesMonitoring: {
                        ...state.meta.gaugesMonitoring,
                        loading: false,
                        ...payload
                    }
                }
            }

        case CONSTANTS.CHART_STRING_MONITORING_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    stringMonitoring: {
                        ...state.meta.stringMonitoring,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_STRING_MONITORING:
            payload = payload ?? {}
            groupBy = payload.groupBy ?? null
            series = payload.series ?? []
            return {
                ...state,
                meta: {
                    ...state.meta,
                    stringMonitoring: {
                        ...state.meta.stringMonitoring,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series,
                            xAxis: payload.xAxis ?? {},
                            yAxis: payload.yAxis ?? {},
                            unixLegend: payload.unixLegend ?? "",
                        },
                    }
                }
            }

        case CONSTANTS.CHART_IV_CURVE_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    IVCurve: {
                        ...state.meta.IVCurve,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_IV_CURVE:
            payload = payload ?? {};
            groupBy = payload.groupBy ?? null
            series = payload.series ?? []
            return {
                ...state,
                meta: {
                    ...state.meta,
                    IVCurve: {
                        ...state.meta.IVCurve,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series
                        }
                    }
                }
            }

        case CONSTANTS.LIST_INVERTER_MONITORING_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    inverterMonitoring: {
                        ...state.meta.inverterMonitoring,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.LIST_INVERTER_MONITORING:
            payload = payload ?? {}
            return {
                ...state,
                meta: {
                    ...state.meta,
                    inverterMonitoring: {
                        ...state.meta.inverterMonitoring,
                        loading: false,
                        ...payload
                    }
                }
            }

        case CONSTANTS.CHART_SPECIFIC_YIELD_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    specificYield: {
                        ...state.meta.specificYield,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_SPECIFIC_YIELD:
            payload = payload ?? {}
            groupBy = payload.groupBy ?? null
            series = payload.series ?? []
            return {
                ...state,
                meta: {
                    ...state.meta,
                    specificYield: {
                        ...state.meta.specificYield,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series
                        }
                    }
                }
            }
        case CONSTANTS.CHART_DAILY_IO_DIAGRAM_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    dailyIODiagram: {
                        ...state.meta.dailyIODiagram,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_DAILY_IO_DIAGRAM:
            if (payload) {
                groupBy = payload.groupBy ?? null
                series = payload.series ?? []
            }

            return {
                ...state,
                meta: {
                    ...state.meta,
                    dailyIODiagram: {
                        ...state.meta.dailyIODiagram,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series
                        }
                    }
                }
            }

        case CONSTANTS.CHART_PERFORMANCE_RATIO_TRACKING_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    performanceRatioTracking: {
                        ...state.meta.performanceRatioTracking,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_PERFORMANCE_RATIO_TRACKING:
            if (payload) {
                groupBy = payload.groupBy ?? null
                series = payload.series ?? []
            }
            return {
                ...state,
                meta: {
                    ...state.meta,
                    performanceRatioTracking: {
                        ...state.meta.performanceRatioTracking,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series
                        }
                    }
                }
            }

        case CONSTANTS.CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    actualProductionVsBudgetProduction: {
                        ...state.meta.actualProductionVsBudgetProduction,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION:
            payload = payload ?? {};
            groupBy = payload.groupBy ?? null
            series = payload.series ?? []
            return {
                ...state,
                meta: {
                    ...state.meta,
                    actualProductionVsBudgetProduction: {
                        ...state.meta.actualProductionVsBudgetProduction,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series
                        }
                    }
                }
            }

        case CONSTANTS.CHART_CARBON_OFFSET_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    carbonOffset: {
                        ...state.meta.carbonOffset,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.CHART_CARBON_OFFSET:
            payload = payload ?? {};
            groupBy = payload.groupBy ?? null
            series = payload.series ?? []
            return {
                ...state,
                meta: {
                    ...state.meta,
                    carbonOffset: {
                        ...state.meta.carbonOffset,
                        loading: false,
                        options: {
                            groupBy: groupBy,
                            series: series
                        }
                    }
                }
            }

        case CONSTANTS.GAUGES_ASSET_PERFORMANCE_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    gaugesPerformance: {
                        ...state.meta.gaugesPerformance,
                        loading: true,
                    }
                }
            }

        case CONSTANTS.GAUGES_ASSET_PERFORMANCE:
            payload = payload ?? {}
            return {
                ...state,
                meta: {
                    ...state.meta,
                    gaugesPerformance: {
                        ...state.meta.gaugesPerformance,
                        loading: false,
                        ...payload
                    }
                }
            }

        case CONSTANTS.LIST_INVERTER_PERFORMANCE_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    inverterPerformance: {
                        ...state.meta.inverterPerformance,
                        loading: true,
                    }
                }
            }
        case CONSTANTS.LIST_INVERTER_PERFORMANCE:
            payload = payload ?? {}
            return {
                ...state,
                meta: {
                    ...state.meta,
                    inverterPerformance: {
                        ...state.meta.inverterPerformance,
                        loading: false,
                        ...payload
                    }
                }
            }
        default:
            return state;
    }
}
