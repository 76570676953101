import React, { Component } from 'react';
import { connect } from "react-redux";
import { AreaChartOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { AntCard, ToolboxControl, AntButton, TagTariffStatus } from "@layouts";
import { Table } from "antd";

class CustomComponent extends Component {
    render = () => {
        const { common } = this.props;
        const parentElementId = "billing-schema";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        const { billingConfigs, onClickDetail } = this.props;
        const { loading, data } = billingConfigs.billingScheme;
        let { from, to, nextId, prevId, billingSchemeTimeOffset, tariff, } = data;

        billingSchemeTimeOffset = billingSchemeTimeOffset ?? [];
        tariff = tariff ?? [];

        console.log(tariff);
        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> Billing Scheme
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                        />
                    </div>
                }
            >
                <div className="card-custom-body billing-schema">
                    <div className="billing-scheme-header">
                        <AntButton
                            icon={<DoubleLeftOutlined/>}
                            onClick={onClickDetail}
                            value={prevId}
                            disabled={!prevId}
                        />
                        <div className="scheme-label">Billing Scheme ({from ?? "-"} - {to ?? "Present"})</div>
                        <AntButton
                            icon={<DoubleRightOutlined/>}
                            onClick={onClickDetail}
                            value={nextId}
                            disabled={!nextId}
                        />
                    </div>
                    <div className={"billing-scheme-time-offset-container"}>
                        <div className="billing-scheme-time-offset">
                            <Table
                                bordered={true}
                                size={"small"}
                                columns={ColumnOffset()}
                                dataSource={billingSchemeTimeOffset}
                                pagination={false}
                                loading={loading}
                                rowKey={"id"}
                                scroll={{ x: 1100 }}
                            >
                            </Table>
                        </div>
                    </div>
                    <div className={"billing-scheme-tariff-container"}>
                        <div className="billing-scheme-tariff">
                            <Table
                                bordered={true}
                                size={"small"}
                                columns={columnsTariff}
                                dataSource={tariff}
                                pagination={false}
                                loading={loading}
                                rowKey={"level"}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            </AntCard>
        )
    }
}

const columnsTariff = [
    {
        ellipsis: true,
        title: "Tariff",
        dataIndex: "name",
        key: "name",
        //align    : "center",
        width: "300px",
    },
    {
        ellipsis: true,
        title: "Price",
        dataIndex: "price",
        key: "price",
        align: "center",
        width: "150px",
    },
    {
        className: "column-color",
        ellipsis: true,
        title: "Color",
        dataIndex: "level",
        key: "level",
        align: "center",
        width: "150px",
        render: (value) => <TagTariffStatus levelSection1={value}/>,
    },
    {
        ellipsis: true,
        title: "Discount",
        dataIndex: "information",
        key: "information",
        //align    : "center",
    },
]

const ColumnOffset = () => {
    let columns = [];
    let idx = 0
    let count = 0
    columns.push({
        ellipsis: true,
        title: "",
        dataIndex: "label",
        key: "label",
        align: "right",
        width: "100px",
    })
    while (idx <= 23) {
        columns.push({
            ellipsis: true,
            title: `${idx}h`,
            dataIndex: idx,
            key: idx,
            align: "center",
            render: value => <TagTariffStatus levelSection1={value[0]} levelSection2={value[1]}/>,
        })
        idx++;
        count++;
    }

    return columns;
}

const mapStateToProps = state => {
    return {
        common: state.common,
        billingConfigs: state.billingConfigs,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);