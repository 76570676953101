import React, { Component } from 'react';
import { connect } from "react-redux";

import { AreaChartOutlined } from "@ant-design/icons";
import { AntCard, ToolboxControl, TagInverterStatus } from "@layouts";
import { Progress, Table, Tag } from "antd";
import helpers from "@ultis/helpers";

class CustomComponent extends Component {
    columns = () => {
        return [
            {
                ellipsis: true,
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (value) => <TagInverterStatus level={value}/>,
                align: "center",
                width: 40,
            },
            {
                ellipsis: true,
                title: 'Power-meters',
                dataIndex: 'name',
                key: 'name',
                width: 80,
                //sorter   : (a, b) => a.name - b.name,
            },
            {
                ellipsis: true,
                title: 'Power (kW)',
                dataIndex: 'currentPower',
                key: 'currentPower',
                render: (value, item) => value,
                    // <div className="col-power">
                    //     <span className="text-value">{value}</span>
                    //     <Progress
                    //         percent={helpers.percentage(item.currentPower, item.continuousPower)}
                    //         showInfo={false}
                    //     />
                    // </div>,
                align: "center",
                sorter: (a, b) => a.currentPower - b.currentPower,
                width: 100,
            },
            {
                ellipsis: true,
                title: 'Today Consumption (kWh)',
                dataIndex: 'todayProduction',
                key: 'todayProduction',
                align: "center",
                width: 110,
                sorter: (a, b) => a.todayProduction - b.todayProduction,
            },
            {
                ellipsis: true,
                title: 'Total Consumption (MWh)',
                dataIndex: 'totalProduction',
                key: 'totalProduction',
                align: "center",
                width: 110,
                sorter: (a, b) => a.totalProduction - b.totalProduction,
            },
            // {
            //     ellipsis: true,
            //     title: 'PR (%)',
            //     dataIndex: 'performanceRatio',
            //     key: 'performanceRatio',
            //     align: "center",
            //     width: 100,
            // },
            /*  {
                  ellipsis : true,
                  title    : 'PLF (%)',
                  dataIndex: 'plantLoadFactor',
                  key      : 'plantLoadFactor',
                  align    : "center",
                  width    : 80
              },*/
            /* {
                 ellipsis : true,
                 title    : 'CUF (%)',
                 dataIndex: 'capacityUtilisationFactor',
                 key      : 'capacityUtilisationFactor',
                 align    : "center",
                 width    : 80
             },*/
            // {
            //     ellipsis: true,
            //     title: 'Energy Yield (kWh/kWp)',
            //     dataIndex: 'energyYield',
            //     key: 'energyYield',
            //     align: "center",
            //     width: 160
            // },
            // {
            //     ellipsis: true,
            //     title: 'Temperature (°C)',
            //     dataIndex: 'temperature',
            //     key: 'temperature',
            //     align: "center",
            //     width: 120
            // },
        ];
    }

    render = () => {
        const { common, meta } = this.props;
        const parentElementId = "plant-InverterMonitoring";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Data from inverter
        const {
            loading,
            total,
            totalPower,
            unit,
            lastTime,
            inverters
        } = meta.inverterMonitoring;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> PowerMeter monitoring
                            <span> </span>
                            <i>- {total} PowerMeters - <Tag color={"orange"}>{totalPower} {unit}</Tag></i>
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            //showSetting={true}
                            updateAt={lastTime}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    <Table
                        columns={this.columns()}
                        dataSource={inverters}
                        pagination={false}
                        loading={loading}
                        rowKey={"id"}
                        scroll={{ x: 1000 }}
                    >
                    </Table>
                </div>
            </AntCard>
        )
    }

}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);