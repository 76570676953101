import React, { Component } from 'react';
import { Table, Typography } from 'antd';
import { DesktopOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { AntCard, ToolboxControl } from "@layouts";

class CustomComponent extends Component {
    props = this['props'];
    render = () => {
        const { common } = this.props;
        const parentElementId = "plant-history-export-report";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Data invoice list
        const { invoiceList } = this.props.billing;
        const { loading, data } = invoiceList;
        const { handleChangeTime, onClickPreviewReport } = this.props;
        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DesktopOutlined/> History export
                        </div>
                        <ToolboxControl
                            disabled
                            parentElementId={parentElementId}
                            selectTimeDefault="this_year"
                            selectTimeOptions={[
                                { title: "This year", value: "this_year" },
                                { title: "2021", value: 2021 },
                                { title: "2020", value: 2020 },
                                { title: "2019", value: 2019 },
                                { title: "2018", value: 2018 },
                                { title: "2017", value: 2017 },
                                { title: "2016", value: 2016 },
                                { title: "2015", value: 2015 },
                                { title: "2014", value: 2014 },
                                { title: "2013", value: 2013 },
                            ]}
                            handleChangeTime={handleChangeTime}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height custom-blocked">
                    <Table
                        columns={columns(onClickPreviewReport)}
                        dataSource={data}
                        pagination={false}
                        loading={loading}
                        rowKey={"id"}
                        size="small"
                    >
                    </Table>
                </div>
            </AntCard>
        )
    }
}

const mapStateToProps = state => {
    return {
        common: state.common,
        billing: state.billing,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);

/**
 * Render Text Custom
 * @param isDisabled
 * @param value
 * @param className
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
const TextCustom = ({ isActive, value, className, onClick, onClickValue }) => {
    return (
        isActive ?
            <Typography.Text
                className={className}
                onClick={onClick}
                data-value={onClickValue}
            >
                {value}
            </Typography.Text>
            : <Typography.Text
                disabled
                className={className}
            >
                {value}
            </Typography.Text>
    )
}

const columns = onClickPreviewReport => {
    return [
        {
            ellipsis: true,
            title: 'Kỳ thanh toán',
            dataIndex: 'paymentPeriod',
            key: 'paymentPeriod',
            align: "center",
            render: (value, item) => <TextCustom
                onClick={onClickPreviewReport}
                onClickValue={item.id}
                className={item.active ? "payment-section" : ""}
                value={value}
                isActive={item.active}
            />,
        },
        {
            ellipsis: true,
            title: 'Ngày xuất báo cáo',
            dataIndex: 'dateExport',
            key: 'dateExport',
            align: "center",
            render: (value, item) => <TextCustom
                value={value}
                isActive={item.active}
            />,
        },
        {
            ellipsis: true,
            title: 'Tiền thanh toán (đồng)',
            dataIndex: 'pricePayment',
            key: 'pricePayment',
            align: "center",
            render: (value, item) => <TextCustom
                value={value}
                isActive={item.active}
            />,
        },
        {
            ellipsis: true,
            title: 'Giảm trừ chiết khấu (đồng)',
            dataIndex: 'priceDiscount',
            key: 'priceDiscount',
            align: "center",
            render: (value, item) => <TextCustom
                value={value}
                isActive={item.active}
            />,
        },
    ];
}