export const GET_CURRENT_BILLING         = 'GET_CURRENT_BILLING';
export const GET_CURRENT_BILLING_LOADING = 'GET_CURRENT_BILLING_LOADING';

export const GET_COST_TREND         = 'GET_COST_TREND';
export const GET_COST_TREND_LOADING = 'GET_COST_TREND_LOADING';

export const GET_INVOICE_LIST         = 'GET_INVOICE_LIST';
export const GET_INVOICE_LIST_LOADING = 'GET_INVOICE_LIST_LOADING';

export const GET_INVOICE_DETAIL         = 'GET_INVOICE_DETAIL';
export const GET_INVOICE_DETAIL_LOADING = 'GET_INVOICE_DETAIL_LOADING';