import React, { Component } from 'react';
import { ToolboxControl, AntCard, Loading } from "@layouts";
import { connect } from "react-redux";
import { DashboardOutlined, BarChartOutlined } from "@ant-design/icons";
import { Col, Divider, Row } from "antd";

class CustomComponent extends Component {
    render = () => {
        const { common, meta } = this.props;
        const parentElementId = "plant-gauges";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Init data
        const {
            loading,
            currentMonthProduction,
            currentPower,
            installedCapacity,
            lastTime,
            reactivePower,
            todayProduction,
            totalProduction,
            totalPowerFactor,
        } = meta.gaugesMonitoring;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DashboardOutlined/> Gauges
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            updateAt={lastTime}
                        />
                    </div>
                }
            >
                <div className="card-custom-body height-350">
                    {loading ? <Loading overwrite/> : null}
                    <div className="group-gauges group-output">
                        <div className="group-title">
                            <DashboardOutlined/> Power Consumption
                        </div>
                        <Row className="group-value">
                            <Col xs={12} xl={12} className="left-value">
                                <div className="wrap-value">
                                    <div className="value">
                                        <div>{currentPower}</div>
                                        <div>{reactivePower}</div>
                                    </div>
                                    <div className="unit">
                                        <div>kW</div>
                                        <div>kVAr</div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} xl={12} className="right-value">
                                <div className="group-item">
                                    <div className="label">
                                        Total Power Factor
                                    </div>
                                    <div className="value">
                                        {totalPowerFactor}
                                    </div>
                                </div>
                                {/*<div className="group-item">*/}
                                {/*    <div className="label">*/}
                                {/*        PV Installed Capacity*/}
                                {/*    </div>*/}
                                {/*    <div className="value">*/}
                                {/*        {installedCapacity} <span>kWp</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </Col>
                        </Row>
                    </div>
                    <Divider className="divider"/>
                    <div className="group-gauges group-yield">
                        <div className="group-title">
                            <BarChartOutlined/> Energy Consumption
                        </div>
                        <Row className="group-value">
                            <Col xs={12} xl={12} className="left-value">
                                <div className="wrap-value">
                                    <div className="value">
                                        <div>{todayProduction}</div>
                                    </div>
                                    <div className="unit">
                                        <div>kWh</div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} xl={12} className="right-value">
                                <div className="group-item">
                                    <div className="label">
                                        Current Month
                                    </div>
                                    <div className="value">
                                        {currentMonthProduction} <span>MWh</span>
                                    </div>
                                </div>
                                <div className="group-item">
                                    <div className="label">
                                        Total
                                    </div>
                                    <div className="value">
                                        {totalProduction} <span>GWh</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </AntCard>
        )
    }

}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);