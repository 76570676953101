import React, { Component } from 'react';
import * as Components from "@features/Configurations/PortfolioConfigurations/components";

class President extends Component {
    render = () => {
        return (
            <div className="features feature-portfolio-configs">
                <h1 className="page-title">
                    Portfolio Configurations
                </h1>
                <Components.FormPortfolioConfigs
                    {...this.props}
                />
            </div>
        )
    }
}

export default President;