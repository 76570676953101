import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles      = StyleSheet.create({
    container     : {
        flexDirection    : "row",
        alignItems       : "center",
        height           : 18,
        fontWeight       : "bold",
        flexGrow         : 1,
    },
    timeType      : {
        width           : "25%",
        textAlign       : "left",
    },
    multiplication: {
        width           : "15%",
        textAlign       : 'right',
    },
    newValue      : {
        width           : "15%",
        textAlign       : "right",
    },
    oldValue      : {
        width    : '15%',
        textAlign: 'right',
    },
    used          : {
        width    : '30%',
        textAlign: 'right',
    },
});

const TableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.timeType}>CÔNG TƠ ĐO ĐẾM</Text>
        <Text style={styles.multiplication}>HỆ SỐ NHÂN</Text>
        <Text style={styles.newValue}>CHỈ SỐ MỚI</Text>
        <Text style={styles.oldValue}>CHỈ SỐ CŨ</Text>
        <Text style={styles.used}>ĐIỆN TIÊU THỤ (kWh)</Text>
    </View>
);

export default TableHeader;