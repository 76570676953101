import initialState from "./initialState";
import * as CONSTANTS from './constants'
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export const reducer = (state = initialState, action) => {
    let { payload, type } = action;
    switch (type) {
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        /**
         * Get device list
         */
        case CONSTANTS.GET_DEVICE_LIST:
            payload = payload ?? {}
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: payload.data ?? [],
                },
            };
        case CONSTANTS.GET_DEVICE_LIST_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                }
            }

        default:
            return state;
    }
}