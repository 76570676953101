import React, { Component } from 'react';
import {
    ToolboxControl
} from "@layouts";
import { connect } from "react-redux";
import { AreaChartOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import * as Plant from "@features/Plant";

class CustomComponent extends Component {
    render = () => {
        const { common, meta, handleChangeTime, onChangeResizeChart } = this.props;

        const parentElementId = "plant-diagram";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Init chart data
        const { loading, options } = meta.dailyIODiagram;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> Daily IO Diagram
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            selectTimeDefault="last_30_days"
                            selectTimeOptions={[
                                { title: "Last 30 days", value: "last_30_days" },
                                { title: "Last 12 months", value: "last_12_months" },
                                { title: "This year", value: "this_year" },
                            ]}
                            handleChangeTime={handleChangeTime}
                            onChangeResize={onChangeResizeChart}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width pd-r-10">
                    {loading ? <Loading overwrite/> : null}
                    <Plant.ChartDailyIODiagram options={options}/>
                </div>
            </AntCard>
        )
    }
}

const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        common: state.common,
        assetPerformance: state.assetPerformance,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);