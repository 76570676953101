import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';

class Container extends Component {
    props = this['props'];
    render = () => {
        const router = this.props.router;
        const code = router.location.pathname.replace("/errors/", "");

        return (
            <President
                code={code}
            />)
    }
}

const mapStateToProps = state => {
    return {
        router: state.router
    }
}

export default connect(mapStateToProps, {})(Container);