import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import { LOCATION_CHANGE } from "connected-react-router";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export const reducer = (state = initialState, action) => {
    let { type } = action;
    switch (type) {
        case LOCATION_CHANGE:
            return {
                ...state,
            };
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        default:
            return state;
    }
}