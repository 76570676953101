import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";

HighchartsHeatmap(Highcharts);

class CustomComponent extends Component {
    render = () => {
        const { options } = this.props;

        // Init chart data
        const { series, xAxis, yAxis, unixLegend } = options;

        // ["String", "Device Name", "Value"]
        let processSeries = !!series && series[0] ? series[0] : [];
        return (
            <HighchartsReact
                containerProps={{ className: "highcharts-react heatmap" }}
                highcharts={Highcharts}
                options={{
                    "chart": {
                        "zoomType": "xy",
                        "type": 'heatmap',
                        "plotBorderWidth": 1,
                        //"marginTop"      : 40,
                        //"marginBottom"   : 80,
                    },
                    "title": {
                        "text": "",
                    },
                    "xAxis": {
                        "categories": xAxis.categories ?? []
                    },

                    "yAxis": {
                        "categories": yAxis.categories ?? [],
                        "title": null,
                    },

                    "colorAxis": {
                        "stops": [
                            [0, "#FFFBF9"],
                            [0.2, "#FEF3EA"],
                            [0.4, "#FBD6B6"],
                            [0.6, "#FBCBA4"],
                            [0.8, "#FBC69C"],
                            [0.9, "#FAC496"],
                            [1, "#F9B982"]
                        ],
                        "min": 0,
                        "max": 12,
                        "startOnTick": false,
                        "endOnTick": false,
                        "labels": {
                            "format": `{value}${unixLegend}`
                        }
                    },

                    "tooltip": {
                        /* formatter: function () {
                             return '<b>' + getPointCategoryName(this.point, 'x') + '</b> of <br><b>' +
                                 this.point.value + '</b> (A) <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
                         }*/
                        formatter: function () {
                            return `<b>${getPointCategoryName(this.point, 'x')}</b>`
                                + ` of<br>`
                                + `<b>${getPointCategoryName(this.point, 'y')}</b><br>`
                                + `<b>${this.point.value}</b>(A)`;
                        }
                    },
                    "legend": {
                        "align": 'right',
                        "layout": 'vertical',
                        "verticalAlign": 'top',
                        "y": -5,
                        "x": 0,
                        "symbolHeight": 352,
                    },
                    "series": [{
                        "borderWidth": 1,
                        //"data"       : data,
                        "data": processSeries.data ?? [],
                        "dataLabels": {
                            //"enabled": true,
                        }
                    }],
                    "plotOptions": {
                        "series": {
                            "animation": false,
                            "maxPointWidth": 100
                        },
                    },
                }}
            />
        )
    }
}

export default CustomComponent;

// function getPointCategoryName(point, dimension) {
//     let series = point.series,
//         isY = dimension === 'y',
//         axis = series[isY ? 'yAxis' : 'xAxis'];
//     return axis.categories[point[isY ? 'y' : 'x']];
// }
const getPointCategoryName = (point, dimension) => {
    let series = point.series, isY = dimension === 'y', axis = series[isY ? 'yAxis' : 'xAxis'];
    return axis.categories[point[isY ? 'y' : 'x']];
}