import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export const reducer = (state = initialState, action) => {
    let { type } = action
    switch (type) {
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        /**
         * Get billing scheme
         */
        case CONSTANTS.GET_BILLING_SCHEME:
            return {
                ...state,
                billingScheme: {
                    ...state.billingScheme,
                    loading: false,
                    data: action.payload ?? {},
                },
            }
        case CONSTANTS.GET_BILLING_SCHEME_LOADING:
            return {
                ...state,
                billingScheme: {
                    ...state.billingScheme,
                    loading: true,
                },
            }

        default:
            return state;
    }
}