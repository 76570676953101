import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { renderLabel, TIMEZONE, tooltipDateFormat } from "../index";

class CustomComponent extends Component {
    render = () => {
        const { options } = this.props;

        // Init chart data
        const { series, groupBy } = options;

        // First line
        const seriesFirst = series.length > 0 ? series[0] : {};
        const dataFirst = seriesFirst.data ?? [];
        const typeFirst = seriesFirst.type ?? '';
        const unitFirst = seriesFirst.unit ?? '';
        const nameFirst = seriesFirst.name ?? '';

        // Second line
        const seriesSecond = series.length > 1 ? series[1] : {};
        const dataSecond = seriesSecond.data ?? [];
        const typeSecond = seriesSecond.type ?? '';
        const unitSecond = seriesSecond.unit ?? '';
        const nameSecond = seriesSecond.name ?? '';

        return (
            <HighchartsReact
                containerProps={{ className: "highcharts-react force-hide-chart-title" }}
                highcharts={Highcharts}
                options={{
                    "chart": {
                        "zoomType": "xy",
                    },
                    "title": {
                        "text": "Cost trend",
                    },
                    "xAxis": [
                        {
                            "type": "datetime",
                            "labels": renderLabel(groupBy)
                        }
                    ],
                    "yAxis": {
                        "min": 0,
                        "labels": {
                            "format": "{value}",
                            "style": {
                                "color": "#666666"
                            }
                        },
                        "title": {
                            "align": 'high',
                            "offset": 0,
                            "text": unitFirst,
                            "rotation": 0,
                            "y": -20,
                            "x": 12,
                        },
                    },
                    "tooltip": {
                        "xDateFormat": tooltipDateFormat(groupBy, TIMEZONE),
                        "shared": true
                    },
                    "legend": {},
                    "series": [
                        {
                            "marker": {
                                "symbol": "square",
                            },
                            "type": typeFirst,
                            "color": "#ffc107",
                            "tooltip": { "valueSuffix": ' ' + unitFirst },
                            "name": nameFirst,
                            "data": dataFirst,
                        }, {
                            "marker": {
                                "symbol": "square"
                            },
                            "type": typeSecond,
                            "color": "#28a745",
                            "tooltip": { "valueSuffix": ' ' + unitSecond },
                            "name": nameSecond,
                            "data": dataSecond,
                        }
                    ],
                    "plotOptions": {
                        "series": {
                            //"animation"    : false,
                            "maxPointWidth": 100
                        },
                    },

                }}
            />
        )
    }
}

export default CustomComponent;