import React, { Component } from 'react';
import { ToolboxControl, AntCard } from "@layouts";
import { connect } from "react-redux";
import { ProjectOutlined } from "@ant-design/icons";
import { Carousel, Image } from "antd";

import sample1 from '@images/sample_architecture.png';
import sample2 from '@images/sample_network.png';

class CustomComponent extends Component {
    onChange = currentSlide => {
        console.log(currentSlide);
    };

    render = () => {
        const { common, detail } = this.props
        const parentElementId = "plant-actual";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        // Init chart data
        //const {loading, options} = detail.components.actualProductionIrradiation

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <ProjectOutlined/>
                            <span> </span>
                            <i>System Architecture</i>
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width">
                    {/*{loading ? <Loading overwrite/> : null}*/}
                    <Carousel
                        className="list-image"
                        afterChange={this.onChange}
                    >
                        <Image src={sample1} height="340px"/>
                        <Image src={sample2} height="340px"/>
                    </Carousel>
                </div>
            </AntCard>
        )
    }
}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);