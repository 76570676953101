import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { renderLabel, TIMEZONE, tooltipDateFormat } from "../index";

class CustomComponent extends Component {
    render = () => {
        const { options } = this.props

        // Init chart data
        const { series, groupBy } = options;

        // First line
        const seriesFirst = series.length > 0 ? series[0] : {}
        const dataFirst = seriesFirst.data ?? []
        const typeFirst = seriesFirst.type ?? ''
        const unitFirst = seriesFirst.unit ?? ''

        // Second line
        const seriesSecond = series.length > 1 ? series[1] : {}
        const dataSecond = seriesSecond.data ?? []
        const typeSecond = seriesSecond.type ?? ''
        const unitSecond = seriesSecond.unit ?? ''

        return (
            <HighchartsReact
                containerProps={{ className: 'highcharts-react' }}
                highcharts={Highcharts}
                options={{
                    "chart": {
                        "zoomType": "xy"
                    },
                    "title": {
                        "text": "",
                    },
                    "xAxis": [
                        {
                            "type": "datetime",
                            "labels": renderLabel(groupBy)
                        }
                    ],
                    "yAxis": [
                        // First line
                        {
                            "min": 0,
                            "labels": {
                                "format": "{value} " + unitFirst,
                                "style": {
                                    "color": "#666666"
                                }
                            },
                            "title": {
                                "text": "",
                            },
                            "opposite": true,
                        },
                        // Secondary line
                        {
                            "min": 0,
                            "gridLineWidth": 0,
                            "title": {
                                "text": "",
                            },
                            "labels": {
                                "format": "{value} " + unitSecond,
                                "style": {
                                    "color": "#666666"
                                }
                            }

                        },
                    ],
                    "tooltip": {
                        "xDateFormat": tooltipDateFormat(groupBy, TIMEZONE),
                        "shared": true
                    },
                    "legend": {},
                    "series": [
                        // {
                        //
                        //     "yAxis": 0,
                        //     "marker": {
                        //         "enabled": false
                        //     },
                        //     "dashStyle": "Solid",
                        //     "zIndex": 4,
                        //     "lineWidth": 1,
                        //     "states": {
                        //         "hover": {
                        //             "enabled": true,
                        //             "lineWidth": 2
                        //         }
                        //     },
                        //     "type": typeFirst,
                        //     "color": "#edbc0e",
                        //     "tooltip": { "valueSuffix": ' ' + unitFirst },
                        //     "name": `Global horizontal irradiance (${unitFirst})`,
                        //     "data": dataFirst,
                        // },
                        {
                            "marker": {
                                "enabled": false
                            },

                            "dashStyle": "Solid",
                            "zIndex": 3,
                            "lineWidth": 1,
                            "states": {
                                "hover": {
                                    "enabled": true,
                                    "lineWidth": 2
                                }
                            },
                            "type": typeSecond,
                            "yAxis": 1,
                            "tooltip": { "valueSuffix": ' ' + unitSecond },
                            "name": `AC Energy (${unitSecond})`,
                            "data": dataSecond,
                        }],
                    "plotOptions": {
                        "series": {
                            "animation": false,
                            "maxPointWidth": 100
                        }
                    }
                }}
            />
        )
    }
}

export default CustomComponent;