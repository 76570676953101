const initialState = {
    detail: {
        loading: false,
        errors : {},
        data   : {},
        id     : null,
        isFound: true,
        //modalVisible: false,
    },

    update: {
        //modalVisible: false,
        loading     : false,
        errors : {},
        data   : {},
    },
};

export default initialState;
