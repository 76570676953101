import React, { Component } from 'react';
import { Link } from "react-router-dom";

class President extends Component {
    render = () => {
        return (
            <div className="">

            </div>
        )
    }
}

export default President;