export const RESET_ACTION                     = 'RESET_ACTION';
export const COMMON_RESET                     = 'COMMON_RESET';
export const BROWSER_FULL_SCREEN_ACTION       = 'BROWSER_FULL_SCREEN_ACTION';
export const BROWSER_EXIT_FULL_SCREEN_ACTION  = 'BROWSER_EXIT_FULL_SCREEN_ACTION';
export const FULL_SCREEN_ACTION               = 'FULL_SCREEN_ACTION';
export const EXIT_FULL_SCREEN_ACTION          = 'EXIT_FULL_SCREEN_ACTION';
export const TOGGLE_SIDER_ACTION              = 'TOGGLE_SIDER_ACTION';
export const SET_DATA_SEARCH_DEFAULT_ACTION   = 'SET_DATA_SEARCH_DEFAULT_ACTION';
export const FETCH_DATA_SEARCH_LOADING_ACTION = 'FETCH_DATA_SEARCH_LOADING_ACTION';
export const FETCH_DATA_SEARCH_ACTION         = 'FETCH_DATA_SEARCH_ACTION';
export const SELECT_DATA_SEARCH_ACTION        = 'SELECT_DATA_SEARCH_ACTION';
export const TOGGLE_MENU_ACTION               = 'TOGGLE_MENU_ACTION';