import * as CONSTANTS from './constants'
import { apiGet } from "@common/crud";

/**
 * Reset store
 * @returns
 */
export const resetStore = () => {
    return {
        type: CONSTANTS.RESET_ACTION,
        payload: null
    };
}

/* ============== Action toggle sider ============== */
export const toggleSider = () => {
    return dispatch => {
        dispatch(toggleSiderAction());
    }
}

const toggleSiderAction = () => {
    return {
        type: CONSTANTS.TOGGLE_SIDER_ACTION,
        payload: null
    };
}

/* ============== Action when click Full screen / Exit full screen ============== */

/* ============== Action when click Full screen / Exit full screen ============== */
export const fullScreen = elementId => {
    return dispatch => {
        dispatch(fullScreenAction(elementId));
    }
}

const fullScreenAction = elementId => {
    return {
        type: CONSTANTS.FULL_SCREEN_ACTION,
        payload: elementId
    };
}

export const exitFullScreen = () => {
    return dispatch => {
        dispatch(exitFullScreenAction());
    }
}

const exitFullScreenAction = () => {
    return {
        type: CONSTANTS.EXIT_FULL_SCREEN_ACTION,
        payload: null
    };
}

export const setBrowserFullScreen = () => {
    document.body.requestFullscreen();
    return dispatch => {
        dispatch(setBrowserFullScreenAction());
    }
}

const setBrowserFullScreenAction = () => {
    return {
        type: CONSTANTS.BROWSER_FULL_SCREEN_ACTION,
        payload: null
    };
}

export const setBrowserExitFullScreen = () => {
    document.exitFullscreen();
    return dispatch => {
        dispatch(browserExitFullScreenAction());
    }
}

const browserExitFullScreenAction = () => {
    return {
        type: CONSTANTS.BROWSER_EXIT_FULL_SCREEN_ACTION,
        payload: null
    };
}

/* ============== Action when click Full screen / Exit full screen ============== */

/* ============== Start Action change scope data ============== */
export const fetchDataSearch = userId => {
    return dispatch => {
        dispatch(fetchDataSearchLoadingAction());
        dispatch(apiGet('/overview/sites-update', {
                "userId": userId,
                "type": "searchList",
            }, {}, fetchDataSearchAction)
        );
    }
}

const fetchDataSearchAction = response => {
    return {
        type: CONSTANTS.FETCH_DATA_SEARCH_ACTION,
        payload: response.data
    };
}

const fetchDataSearchLoadingAction = () => {
    return {
        type: CONSTANTS.FETCH_DATA_SEARCH_LOADING_ACTION,
        payload: {
            loading: true,
        }
    };
}

export const selectDataSearch = (portfolioId, siteId, plantId) => {
    return dispatch => {
        dispatch(selectDataSearchAction(portfolioId, siteId, plantId));
    }
}

const selectDataSearchAction = (portfolioId, siteId, plantId) => {
    return {
        type: CONSTANTS.SELECT_DATA_SEARCH_ACTION,
        payload: {
            "portfolioId": portfolioId,
            "siteId": siteId,
            "plantId": plantId,
        }
    };
}

export const toggleMenu = () => {
    return dispatch => {
        dispatch(toggleMenuAction());
    }
}

const toggleMenuAction = () => {
    return {
        type: CONSTANTS.TOGGLE_MENU_ACTION,
        payload: null
    };
}

/* ============== End Action change scope data ============== */