import React, { Component } from 'react';
import { ToolboxControl, AntCard, Loading, DataEmpty, } from "@layouts";
import { connect } from "react-redux";
import { DashboardOutlined } from "@ant-design/icons";
import { Divider, Badge, Checkbox } from "antd";
import helpers from "@ultis/helpers";

class CustomComponent extends Component {
    render = () => {
        const { common } = this.props;
        const parentElementId = "plant-gauges";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        const { meta, onSelectedRoles, selectedRoles, assignGroups } = this.props;

        // Init data
        const { loading, } = meta.gaugesPerformance;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DashboardOutlined/> Person In Charge
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                        />
                    </div>
                }
            >
                <div className="card-custom-body card-person-in-charge">
                    {loading ? <Loading overwrite/> : null}
                    <GroupPersonInCharge
                        onSelectedRoles={onSelectedRoles}
                        selectedRoles={selectedRoles}
                        assignGroups={assignGroups}
                    />
                </div>
            </AntCard>
        )
    }

}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);

const GroupPersonInCharge = ({ onSelectedRoles, selectedRoles, assignGroups }) => {
    assignGroups = assignGroups ? assignGroups : [];
    const length = assignGroups.length
    return (
        length === 0 ? <DataEmpty/> :
            assignGroups.map((group, idx) => {
                return (
                    <div key={idx}>
                        <div className="group-person-in-charge">
                            <div className="group-title">
                                <Checkbox
                                    onChange={onSelectedRoles}
                                    name={group.roleId}
                                    checked={selectedRoles[group.roleId]}
                                >
                                    {group.roleName}
                                </Checkbox>
                            </div>
                            <div className="group-person">
                                {
                                    group.users.map((user, idxUser) => {
                                        return (
                                            <div className="person-information" key={idxUser}>
                                                <div className="label">
                                                    <Badge color={helpers.stringToColor(user.name)} text={user.title}/>
                                                </div>
                                                <div className="value">
                                                    {user.name} | {user.phone}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            (idx < length - 1) ? <Divider className="divider"/> : []
                        }
                    </div>
                )
            })
    )
}