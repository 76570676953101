import initialState from "./initialState";
import {
    SET_TOKEN_ACTION, CLEAR_TOKEN_ACTION, ARG_TOKEN, LOGIN_LOADING, UPDATE_TOKEN_ACTION
} from "./constants";

export const reducer = (state = initialState, action) => {
    const stateLocal = loadStateFromLocal();
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state,
                login: {
                    ...state.login,
                    loading: true
                }
            };
        case UPDATE_TOKEN_ACTION:
            updateStateLocal(action.payload)
            return {
                ...state,
                ...stateLocal,
            };
        case SET_TOKEN_ACTION:
        case CLEAR_TOKEN_ACTION:
            return {
                ...state,
                ...stateLocal,
                login: {
                    loading: false
                }
            };
        default:
            return {
                ...state,
                ...stateLocal,
            };
    }
}

const updateStateLocal = (dataNew = {}) => {
    try {
        // Load data from local
        let dataLocal = JSON.parse(localStorage.getItem(ARG_TOKEN));

        let nameNew    = dataNew.name ?? null;
        let logoUrlNew = dataNew.logoUrl ?? null;

        let portfolioCurrent     = dataLocal.data.portfolio ?? {};
        portfolioCurrent         = {
            ...portfolioCurrent,
            name   : nameNew,
            logoUrl: logoUrlNew,
        }
        dataLocal.data.portfolio = portfolioCurrent;

        // Update local storage
        localStorage.setItem(ARG_TOKEN, JSON.stringify(dataLocal))
    } catch (e) {
        console.log(e);
    }
}

export const loadStateFromLocal = () => {
    let stateFromLocal;
    try {
        // Load data from local
        let dataLocal = JSON.parse(localStorage.getItem(ARG_TOKEN));

        // Load data auth
        const { data, meta }               = dataLocal;
        const { token, role, permissions } = meta;

        // Render config homepage
        let homepage;
        switch (role) {
            case "O&M":
                homepage = "/technical-monitoring";
                break;
            case "AM":
                homepage = "/asset-performance";
                break;
            case "EM":
            case "SA":
            default:
                homepage = "/";
                break;
        }

        // Render permission
        let dataPermissions = [];
        permissions.forEach(item => {
            dataPermissions.push(item.route);
        });
        let portfolio  = data.portfolio ?? initialState.user.portfolio;
        stateFromLocal = {
            user   : {
                id       : data._id,
                name     : data.name,
                email    : data.email,
                portfolio: {
                    id     : portfolio.id ?? null,
                    logoUrl: portfolio.logoUrl ?? null,
                    name   : portfolio.name ?? null,
                }
            },
            meta   : {
                token      : token,
                role       : role,
                permissions: dataPermissions
            },
            configs: {
                homepage: homepage
            },
        }
    } catch (e) {
        localStorage.removeItem(ARG_TOKEN)
        stateFromLocal = {
            ...initialState
        }
    }
    return stateFromLocal;
}