export const REPORT_GET_LIST = 'REPORT_GET_LIST'
export const REPORT_GET_LIST_LOADING = 'REPORT_GET_LIST_LOADING'

export const REPORT_GET_DETAIL = 'REPORT_GET_DETAIL'
export const REPORT_GET_DETAIL_LOADING = 'REPORT_GET_DETAIL_LOADING'

export const REPORT_UPDATE = 'REPORT_UPDATE'
export const REPORT_UPDATE_LOADING = 'REPORT_UPDATE_LOADING'

export const REPORT_REQUEST_DOWNLOAD = 'REPORT_REQUEST_DOWNLOAD'
export const REPORT_REQUEST_DOWNLOAD_LOADING = 'REPORT_REQUEST_DOWNLOAD_LOADING'