import React, { Component } from 'react';
import { Skeleton, Modal } from "antd";
import * as Components from "../../components";

class President extends Component {
    render = () => {
        const {
            detail,
            previewVisible,
            onClickPreviewReport,
            onCancelExport,
            handleChangeTimeCostTrend,
            handleChangeTimeInvoiceList,
        } = this.props;

        // Render data
        const { data, loading } = detail;

        return (
            <div className="features feature-plant">
                <h1 className="page-title">
                    {loading ? <Skeleton.Input active={loading}/> : data.name}
                </h1>
                <Components.QuarterReport/>
                <Components.CostTrend
                    handleChangeTime={handleChangeTimeCostTrend}
                />
                <Components.HistoryExportReport
                    handleChangeTime={handleChangeTimeInvoiceList}
                    onClickPreviewReport={onClickPreviewReport}
                />
                <Modal
                    className={"modal-preview"}
                    title="Billing"
                    width={1024}
                    style={{ top: 48 }}
                    visible={previewVisible}
                    onCancel={onCancelExport}
                    onOk={onCancelExport}
                    footer={null}
                >
                    <Components.BillingReportPdf/>
                </Modal>
            </div>

        )
    }
}

export default President;