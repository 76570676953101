import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { fetchPlants } from "../../redux";
import { resetStore } from "@features/Common/redux";
import { getSiteDetail } from "@features/Site/redux";
import { fetchTableEvent } from "@features/Event/redux";
import * as CONSTANTS from "@ultis/constants";

class Container extends Component {
    props = this['props'];

    onChangeResizeMap = () => {
        const { siteId } = this.props.match.params;
        this.props.fetchPlants(siteId);
    }

    render = () => {
        return (
            <President
                {...this.props}
                onChangeResizeMap={this.onChangeResizeMap}
            />)
    }

    componentDidMount = () => {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData())
    }

    componentDidUpdate = prevProps => {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if ((preParams.siteId !== currentParams.siteId)) {
            this.fetchData();
        }
    }

    componentWillUnmount = () => {
        this.props.resetStore();
    }

    fetchData = () => {
        const { siteId, plantId } = this.props.match.params;
        this.props.getSiteDetail(siteId)
        this.props.fetchPlants(siteId);
        this.props.fetchTableEvent({
            plantId: plantId,
            siteId: siteId,
        })
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        getSiteDetail: siteId => {
            dispatch(getSiteDetail(siteId));
        },
        fetchPlants: siteId => {
            dispatch(fetchPlants(siteId));
        },
        fetchTableEvent: params => {
            dispatch(fetchTableEvent(params));
        },
    };
}


const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        auth: state.auth,
        common: state.common,
        site: state.site,
        technicalMonitoring: state.technicalMonitoring,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));