import * as CONSTANTS from "./constants";
import { apiGet } from "@common/crud";

/**
 * Get current billing
 * @param plantId
 * @returns
 */
export const getCurrentBilling = plantId => {
    return dispatch => {
        dispatch(getCurrentBillingActionLoading());
        dispatch(apiGet(`billing/${plantId}`, {}, {}, getCurrentBillingAction));
    };
}

const getCurrentBillingAction = response => {
    return {
        type: CONSTANTS.GET_CURRENT_BILLING,
        payload: response.data,
    };
}

const getCurrentBillingActionLoading = () => {
    return {
        type: CONSTANTS.GET_CURRENT_BILLING_LOADING,
        payload: null,
    };
}

/**
 * Get cost trend
 * @returns
 * @param params
 */
export const getCostTrend = (params = {}) => {
    const { plantId, from, to } = params;
    return dispatch => {
        dispatch(getCostTrendActionLoading());
        dispatch(apiGet(`billing/${plantId}/cost-trend`, {
            "from": from ?? null,
            "to": to ?? null,
        }, {}, getCostTrendAction));
    };
}

const getCostTrendAction = response => {
    return {
        type: CONSTANTS.GET_COST_TREND,
        payload: response.data
    };
}

const getCostTrendActionLoading = () => {
    return {
        type: CONSTANTS.GET_COST_TREND_LOADING,
        payload: null
    };
}


/**
 * Get invoice list
 * @returns
 * @param params
 */
export const getInvoiceList = (params = {}) => {
    const { plantId, from, to } = params;
    return dispatch => {
        dispatch(getInvoiceListActionLoading());
        dispatch(apiGet(`billing/${plantId}/invoice`, {
            "from": from ?? null,
            "to": to ?? null,
        }, {}, getInvoiceListAction));
    };
}

const getInvoiceListAction = response => {
    return {
        type: CONSTANTS.GET_INVOICE_LIST,
        payload: response.data
    };
}

const getInvoiceListActionLoading = () => {
    return {
        type: CONSTANTS.GET_INVOICE_LIST_LOADING,
        payload: null
    };
}

/**
 * Get invoice detail
 * @returns
 * @param params
 */
export const getInvoiceDetail = (params = {}) => {
    const { plantId, invoiceId } = params;
    return dispatch => {
        dispatch(getInvoiceDetailActionLoading());
        dispatch(apiGet(`billing/${plantId}/invoice/${invoiceId}`, {}, {}, getInvoiceDetailAction));
    };
}

const getInvoiceDetailAction = response => {
    return {
        type: CONSTANTS.GET_INVOICE_DETAIL,
        payload: response.data
    };
}

const getInvoiceDetailActionLoading = () => {
    return {
        type: CONSTANTS.GET_INVOICE_DETAIL_LOADING,
        payload: null
    };
}