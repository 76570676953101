import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import {
    getPlantDetail,
} from "@features/Plant/redux";
import * as CONSTANTS from "@ultis/constants";
import { resetStore } from "@features/Common/redux";
import { getBillingScheme } from "@features/Configurations/BillingConfigurations/redux";

class Container extends Component {

    props = this['props'];

    constructor(props) {
        super(props);
        this.state = {}
    }

    onClickDetail = e => {
        const { plantId } = this.props.match.params;
        const schemeId = e.currentTarget.value ?? null;

        // Fetch data billing scheme of plant
        this.props.getBillingScheme(plantId, schemeId);
    }

    render = () => {
        const { detail } = this.props.plant;

        if (!detail.isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }

        return (
            <President
                {...this.state}
                detail={detail}
                onClickDetail={this.onClickDetail}
            />)
    }

    componentDidMount = () => {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData());
    }

    componentWillUnmount = () => {
        this.props.resetStore();
    }

    componentDidUpdate = prevProps => {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if (preParams.plantId !== currentParams.plantId) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { plantId } = this.props.match.params;

        // Fetch data plaint detail
        this.props.getPlantDetail(plantId);

        // Fetch data billing scheme of plant
        this.props.getBillingScheme(plantId);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        getPlantDetail: plantId => {
            dispatch(getPlantDetail(plantId));
        },
        getBillingScheme: (plantId, schemeId = null) => {
            dispatch(getBillingScheme(plantId, schemeId));
        },
    };
}

const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        common: state.common,
        auth: state.auth,
        plant: state.plant,
        billingConfigs: state.billingConfigs,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
