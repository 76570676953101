import * as CONSTANTS from "./constants";
import { apiGet, apiPostForm, CODE_SUCCESS } from "@common/crud";
import { pushMessageSuccess } from "@layouts";

export const getPortfolioDetail = id => {
    return dispatch => {
        dispatch(portfolioDetailLoadingAction());
        dispatch(apiGet('portfolio/' + id, {}, {}, portfolioDetailAction));
    };
}

export const portfolioDetailAction = response => {
    return {
        type: CONSTANTS.PORTFOLIO_DETAIL,
        payload: response
    };
}

export const portfolioDetailLoadingAction = () => {
    return {
        type: CONSTANTS.PORTFOLIO_DETAIL_LOADING,
        payload: null
    };
}

export const updatePortfolio = (id, params) => {
    const { name, logo } = params;

    let newParams = {
        "name": name,
    }
    if (logo) {
        newParams["logo"] = logo;
    }
    return dispatch => {
        dispatch(portfolioUpdateLoadingAction());
        dispatch(apiPostForm('portfolio/' + id, newParams, {}, portfolioUpdateAction));
    }
}

export const portfolioUpdateAction = response => {
    if (response.code === CODE_SUCCESS) {
        pushMessageSuccess();
    }
    return {
        type: CONSTANTS.PORTFOLIO_UPDATE,
        payload: response
    };
}

export const portfolioUpdateLoadingAction = () => {
    return {
        type: CONSTANTS.PORTFOLIO_UPDATE_LOADING,
        payload: null
    };
}