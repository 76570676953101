import React, { Component } from 'react';
import { Row, Col } from 'antd';
import * as Components from "../../components";

class President extends Component {
    render = () => {
        const {
            onChangeDataType,
            parentId,
            setDetailParams,
            params,
            currentDataType,
            dataType,
        } = this.props;

        return (
            <div className="features feature-home">
                <h1 className="page-title">
                    Analysis
                </h1>
                <Row gutter={{ xs: 8, sm: 12, md: 16 }}>
                    <Col xs={24} xl={8}>
                        <Components.AnalysisListComponent
                            onChangeDataType={onChangeDataType}
                            parentId={parentId}
                            currentDataType={currentDataType}
                        />
                    </Col>
                    <Col xs={24} xl={16}>
                        <Components.AnalysisDetailComponent
                            setDetailParams={setDetailParams}
                            dataType={dataType}
                            params={params}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default President;