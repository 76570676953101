import React, { Component } from 'react';
import President from './President';
import { clearToken } from "@features/Auth/redux/actions";
import { connect } from "react-redux";

class Container extends Component {
    render = () => {
        return (<President {...this.props}/>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearToken: () => {
            dispatch(clearToken());
        },
    };
}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);