import * as CONSTANTS from './constants'
import { apiGet } from "@common/crud";

export const getDeviceList = plantId => {
    return dispatch => {
        dispatch(getDeviceListActionLoading());
        dispatch(apiGet('devices', {
            "plantId": plantId ?? ""
        }, {}, getDeviceListAction));
    };
}

const getDeviceListAction = response => {
    return {
        type: CONSTANTS.GET_DEVICE_LIST,
        payload: response
    };
}

const getDeviceListActionLoading = () => {
    return {
        type: CONSTANTS.GET_DEVICE_LIST_LOADING,
        payload: null
    };
}