import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { getPlantDetail, } from "@features/Plant/redux";
import {
    getCurrentBilling,
    getCostTrend,
    getInvoiceList,
    getInvoiceDetail,
} from "../../redux";
import * as CONSTANTS from "@ultis/constants";
import helpers from "@ultis/helpers";
import moment from 'moment';

class Container extends Component {
    props = this['props'];

    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            costTrend: {
                timeRange: CONSTANTS.FILTER_THIS_YEAR
            },
            invoiceList: {
                year: moment().year,
            }
        }
    }

    onClickPreviewReport = e => {
        const { plantId } = this.props.match.params;
        const value = e.currentTarget.dataset.value;
        this.setState({
            ...this.state,
            previewVisible: true
        }, this.props.getInvoiceDetail({
            "plantId": plantId,
            "invoiceId": value,
        }));
    }

    onCancelExport = () => {
        this.setState({
            ...this.state,
            previewVisible: false
        });
    }

    handleChangeTimeCostTrend = value => {
        const { plantId } = this.props.match.params;
        let range = helpers.getTimeRangeByFilterTime(value);

        this.setState({
            ...this.state,
            costTrend: {
                ...this.state.costTrend,
                timeRange: value
            }
        }, this.props.getCostTrend({
            "plantId": plantId,
            "from": range.fromString,
            "to": range.toString,
        }));
    }

    handleChangeTimeInvoiceList = value => {
        const { plantId } = this.props.match.params;
        let invoiceListRange = helpers.getTimeRangeByYear(value);
        this.setState({
            ...this.state,
            invoiceList: {
                ...this.state.invoiceList,
                year: value,
            }
        }, this.props.getInvoiceList({
            "plantId": plantId,
            "from": invoiceListRange.fromString,
            "to": invoiceListRange.toString,
        }));
    }

    render = () => {
        const { detail } = this.props.plant;
        const { previewVisible } = this.state;

        if (!detail.isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }
        return (
            <President
                previewVisible={previewVisible}
                detail={detail}
                handleChangeTimeCostTrend={this.handleChangeTimeCostTrend}
                handleChangeTimeInvoiceList={this.handleChangeTimeInvoiceList}
                onClickPreviewReport={this.onClickPreviewReport}
                onCancelExport={this.onCancelExport}
            />)
    }

    componentDidMount = () => {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData());
    }

    componentDidUpdate = prevProps => {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if (preParams.plantId !== currentParams.plantId) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { plantId } = this.props.match.params;
        const { costTrend, invoiceList } = this.state;

        // Fetch plant detail
        this.props.getPlantDetail(plantId);

        // Fetch current billing
        this.props.getCurrentBilling(plantId);

        // Fetch current billing
        let costTrendRange = helpers.getTimeRangeByFilterTime(costTrend.timeRange);
        this.props.getCostTrend({
            "plantId": plantId,
            "from": costTrendRange.fromString,
            "to": costTrendRange.toString,
        });

        // Fetch invoice list
        let invoiceListRange = helpers.getTimeRangeByYear(invoiceList.year)
        this.props.getInvoiceList({
            "plantId": plantId,
            "from": invoiceListRange.fromString,
            "to": invoiceListRange.toString,
        });
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPlantDetail: plantId => {
            dispatch(getPlantDetail(plantId));
        },

        getCurrentBilling: plantId => {
            dispatch(getCurrentBilling(plantId));
        },

        getCostTrend: params => {
            dispatch(getCostTrend(params));
        },

        getInvoiceList: params => {
            dispatch(getInvoiceList(params));
        },

        getInvoiceDetail: params => {
            dispatch(getInvoiceDetail(params));
        },
    };
}


const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        common: state.common,
        plant: state.plant,
        billing: state.billing,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));