import React, { Component } from 'react';
import { Document, Page, Text, View, PDFViewer, Font, } from "@react-pdf/renderer";
import fontRegular from "@src/fonts/OpenSans/OpenSans-Regular.ttf";
import fontRegularItalic from "@src/fonts/OpenSans/OpenSans-Italic.ttf";
import fontBold from "@src/fonts/OpenSans/OpenSans-Bold.ttf";
import { PaymentTable, PowerUsedTable } from "@features/Billing/components";
import { connect } from "react-redux";
import { Loading } from '@src/layouts';

const pageSize = "A4";
const colorPrimary = "#096dd9";

// Register Font
Font.register({
    family: 'fontFamily', fonts: [
        { src: fontRegular },
        { src: fontRegularItalic, fontStyle: "italic" },
        { src: fontBold, fontWeight: "bold" },
    ]
});

class CustomComponent extends Component {
    props = this['props'];

    render = () => {
        // Data current billing
        const { invoiceDetail } = this.props.billing;
        const { loading, data } = invoiceDetail;

        let {
            consumptionByTimeSlots,
            customer,
            discountPrice,
            discountRatio,
            expireDate,
            exportDate,
            invoicePeriod,
            priceByTimeSlots,
            supplier,
            taxPrice,
            taxRatio,
            totalConsumption,
            totalPriceByConsumption,
            totalPriceNotInTax,
            totalPrice,
            totalPriceByText,
        } = data;

        supplier = supplier ?? {};
        customer = customer ?? {};

        let supplierName = supplier.name ?? "";
        let supplierContact = supplier.contact ?? "";
        let supplierAddress = supplier.address ?? "";
        let supplierGroup = supplier.group ?? "";
        let supplierTax = supplier.tax ?? "";

        let customerId = customer.id ?? "";
        let customerName = customer.name ?? "";
        let customerAddress = customer.address ?? "";
        let customerPhone = customer.phone ?? "";
        let customerEmail = customer.email ?? "";
        let customerTax = customer.tax ?? "";
        let addressUseElectric = customer.addressUseElectric ?? "";
        let electricType = customer.electricType ?? "";
        let periodInvoice = customer.periodInvoice ?? "";

        const purpose = customer.purpose ?? [];
        let purposeText = purpose.join(" \n ");

        return (
            <div
                style={{
                    width: "100%",
                    minHeight: 800,
                }}
            >
                {
                    loading ?
                        <Loading/>
                        :
                        <PDFViewer
                            style={{
                                width: "100%",
                                minHeight: 800,
                            }}
                        >
                            <Document>
                                <MainPage size={pageSize}>
                                    <PageHeader
                                        supplierName={supplierName}
                                        supplierAddress={supplierAddress}
                                        supplierGroup={supplierGroup}
                                        supplierTax={supplierTax}
                                    />
                                    <Title/>
                                    <BaseLayout>
                                        <BaseLayoutLeft>
                                            <View>
                                                <UserField label={"Khách hàng"} value={customerName}/>
                                                <UserField label={"Địa chỉ"} value={customerAddress}/>
                                                <UserField label={"Điện thoại"} value={customerPhone}/>
                                                <UserField label={"Email"} value={customerEmail}/>
                                                <UserField label={"Mã số thuế"} value={customerTax}/>
                                                <UserField label={"Địa chỉ sử dụng điện"} value={addressUseElectric}/>
                                                <UserField label={"Mục đích sử dụng điện"} value={purposeText}/>
                                                <UserField label={"Cấp điện áp sử dụng"} value={electricType}/>
                                            </View>
                                            <TitleSub value={"TÌNH HÌNH SỬ DỤNG ĐIỆN CỦA KHÁCH HÀNG"}/>
                                            <Text>
                                                Kỳ hóa đơn: {invoicePeriod}
                                            </Text>
                                            <PowerUsedTable
                                                tableData={consumptionByTimeSlots}
                                                totalConsumption={totalConsumption}
                                            />
                                            <TitleSub value={"TỔNG SỐ TIỀN THANH TOÁN"}/>
                                            <PaymentTable
                                                tableData={priceByTimeSlots}
                                                tableFooter={
                                                    {
                                                        discountPrice: discountPrice,
                                                        discountRatio: discountRatio,
                                                        totalConsumption: totalConsumption,
                                                        taxRatio: taxRatio,
                                                        taxPrice: taxPrice,
                                                        totalPriceByConsumption: totalPriceByConsumption,
                                                        totalPriceNotInTax: totalPriceNotInTax,
                                                        totalPrice: totalPrice,
                                                        totalPriceByText: totalPriceByText,
                                                    }
                                                }
                                            />
                                        </BaseLayoutLeft>

                                        <BaseLayoutRight>
                                            <BoxInformation
                                                label="Mã khách hàng"
                                                value={customerId}
                                            />
                                            <BoxInformation
                                                label="Số tiền thanh toán"
                                                value={totalPrice}
                                                isCash={true}
                                            />
                                            <BoxInformation
                                                label="Hạn thanh toán"
                                                value={expireDate}
                                            />
                                            <NotePayment/>
                                            <ContactSection supplierContact={supplierContact}/>
                                            <SignCompany
                                                supplierName={supplierName}
                                                exportDate={exportDate}/>
                                        </BaseLayoutRight>
                                    </BaseLayout>
                                </MainPage>
                            </Document>
                        </PDFViewer>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        billing: state.billing,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);

const SignCompany = ({ supplierName, exportDate }) => {
    return (
        <View
            style={{
                marginTop: 6,
                fontSize: 10,
                textAlign: "center",
                fontWeight: "bold"
            }}
        >
            <Text>BÊN BÁN ĐIỆN</Text>
            <Text style={{
                fontWeight: "normal"
            }}>Ngày ký {exportDate}</Text>
            <Text>Người ký : {supplierName ?? ""}</Text>
        </View>
    )
}

const ContactSection = ({ supplierContact }) => {
    return (
        <View>
            <TitleSub value={"THÔNG TIN LIÊN HỆ"}/>
            <Text>
                {supplierContact ?? ""}
            </Text>
        </View>
    )
}

const NotePayment = () => {
    return (
        <Text
            style={{
                fontStyle: "italic"
            }}
        >
            Để tránh phát sinh các chi phí phạt vi phạm hợp đồng và lãi suất phạt chậm trả, đề nghị Quý khách hàng
            thanh toán đúng hạn.
        </Text>
    )
}

const BoxInformation = ({ label, value, isCash }) => {
    return (
        <View
            style={{
                marginBottom: 6,
                backgroundColor: "#096dd9",
                color: "#fff",
                textAlign: "center",
                padding: 8,
                fontSize: 10
            }}
        >
            <Text>{label}</Text>
            <Text
                style={[
                    {
                        fontWeight: "bold",
                        color: isCash ? "yellow" : "#fff",
                    },
                ]}
            >
                {value}
            </Text>
        </View>
    )
}

const BaseLayout = props => {
    return (
        <View
            style={{
                flexDirection: "row",
            }}
        >
            {props.children}
        </View>
    )
}

const BaseLayoutLeft = props => {
    return (
        <View
            style={{
                flexGrow: 1,
                flexShrink: 0,
                flexBasis: 570,
            }}
        >
            {props.children}
        </View>
    )
}

const BaseLayoutRight = props => {
    return (
        <View
            style={{
                flexGrow: 0,
                flexShrink: 1,
                flexBasis: 200,
                paddingLeft: 8,
            }}>
            {props.children}
        </View>
    )
}

const TitleSub = props => {
    const { value } = props;
    return (
        <View
            style={{
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}
        >
            <Text
                style={{
                    color: colorPrimary,
                    fontWeight: "bold",
                    borderBottom: `1px solid ${colorPrimary}`,
                    marginBottom: 6,
                    marginTop: 6,
                    fontSize: 9
                }}
            >
                {value}
            </Text>
        </View>
    )
}

const UserField = props => {
    const { label, value } = props;
    return (
        <View
            style={{
                marginBottom: 6,
            }}
        >
            <View
                style={{
                    flexDirection: "row",
                    paddingRight: 10
                }}
            >
                <View
                    style={{
                        flexGrow: 0,
                        flexShrink: 1,
                        flexBasis: 100,
                        textAlign: "left"
                    }}
                >
                    <Text>{label}</Text>
                </View>
                <View
                    style={{
                        flexGrow: 1,
                        flexShrink: 0,
                        flexBasis: 100,
                        fontWeight: "bold",
                    }}
                >
                    <Text>{value}</Text>
                </View>
            </View>
        </View>
    )
}

const Title = () => {
    return (
        <View
            style={{
                textAlign: "center",
            }}
        >
            <Text
                style={{
                    color: colorPrimary,
                    fontSize: 14,
                    fontWeight: "bold"
                }}
            >
                HÓA ĐƠN GIÁ TRỊ GIA TĂNG (TIỀN ĐIỆN)
            </Text>
            <Text
                style={{
                    fontSize: 8,
                    marginBottom: 24,
                }}
            >
                (Bản thể hiện của hóa đơn điện tử)
            </Text>
        </View>
    )
}

const PageHeader = ({ supplierName, supplierAddress, supplierGroup, supplierTax }) => {
    return (
        <View
            style={{
                flexDirection: "row",
                borderBottom: `1px solid ${colorPrimary}`,
                paddingBottom: 6,
                marginBottom: 12,
            }}
        >
            <View
                style={{
                    flexGrow: 1,
                    flexShrink: 0,
                }}
            >
                <Text
                    style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        marginBottom: 6,
                    }}
                >
                    {supplierGroup}
                </Text>
                <Text
                    style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        marginBottom: 6
                    }}
                >
                    {supplierName}
                </Text>
                <Text>
                    {supplierAddress}
                </Text>
                <Text>
                    MST: {supplierTax}
                </Text>
            </View>
            <View
                style={{
                    flexGrow: 0,
                    flexShrink: 1,
                    flexBasis: 200,
                    paddingLeft: 12,
                    textAlign: "right",
                    justifyContent: "flex-end"
                }}
            >
                <Text>
                    19001006 - 19009000
                </Text>
                <Text>Mẫu số: 01GTKT0/005</Text>
                <Text>Ký hiệu: AA/22E</Text>
                <Text>Số: 0087046</Text>
            </View>
        </View>
    )
}

const MainPage = ({ children, size }) => {
    return (
        <Page
            size={size}
            style={{
                fontFamily: "fontFamily",
                backgroundColor: "#fff",
                color: "#000",
                padding: "1.8cm 2.54cm",
                fontSize: 8,
            }}
        >
            {children}
        </Page>
    )
}