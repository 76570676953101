import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";
import { CODE_SUCCESS } from "@common/crud";
import { pushMessageSuccess } from "@layouts";

export const reducer = (state = initialState, action) => {
    let { payload, type } = action;

    switch (type) {
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        case CONSTANTS.REPORT_GET_LIST:
            let list = payload ? payload.data : []
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: list ?? []
                }
            }
        case CONSTANTS.REPORT_GET_LIST_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
                // Reset detail & update
                detail: { ...initialState.detail },
                update: { ...initialState.update }
            }
        case CONSTANTS.REPORT_GET_DETAIL:
            let detail = payload ? payload.data : {}
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    data: detail ?? {}
                }
            }
        case CONSTANTS.REPORT_GET_DETAIL_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                }
            }
        case CONSTANTS.REPORT_UPDATE:
            let update = payload ? payload.data : {}
            if (payload.code === CODE_SUCCESS) {
                pushMessageSuccess("Update Automatic Export success.");
            }
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    data: update ?? {}
                }
            }
        case CONSTANTS.REPORT_UPDATE_LOADING:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: true,
                }
            }
        case CONSTANTS.REPORT_REQUEST_DOWNLOAD:
            return {
                ...state,
                downloadRequest: {
                    ...state.downloadRequest,
                    loading: false,
                    data: {
                        url: payload ? payload.url : null,
                    }
                }
            }
        case CONSTANTS.REPORT_REQUEST_DOWNLOAD_LOADING:
            return {
                ...state,
                downloadRequest: {
                    ...state.downloadRequest,
                    loading: true,
                }
            }
        default:
            return state;
    }
}