import React, { Component } from 'react';
import { Alert } from 'antd';

class President extends Component {
    render = () => {

        return (
            <div className="features feature-home">
                <h1 className="page-title">
                    ENTIRE DOMAIN
                </h1>
                <Alert message="Please choose a scope plant." type="warning"/>
            </div>
        );
    }
}

export default President;
