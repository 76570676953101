import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { renderSeriesDailyIODiagram } from "../index";
import highchartsAccessibility from "highcharts/modules/accessibility";

highchartsAccessibility(Highcharts);

class CustomComponent extends Component {
    render = () => {
        const { options } = this.props;

        // Init chart data
        const { series } = options;
        const { chartSeries, chartTooltip } = renderSeriesDailyIODiagram(series);

        const unixX = "kWh/m2/day";
        const unixY = "kWh/day";

        return (
            <HighchartsReact
                containerProps={{ className: "highcharts-react force-hide-chart-title" }}
                highcharts={Highcharts}
                options={{
                    "chart": {
                        "type": "scatter",
                        "zoomType": "xy"
                    },
                    "title": {
                        "text": " Daily IO Diagram",
                    },
                    "xAxis": [
                        {
                            "title": {
                                "text": unixX,
                                "align": "high",
                                "x": 10,
                            },
                        }
                    ],
                    "yAxis": [
                        // First line
                        {
                            "min": 0,
                            "labels": {
                                "format": `{value}`,
                                "style": {
                                    "color": "#666666"
                                }
                            },
                            "title": {
                                "align": 'high',
                                "offset": 0,
                                "text": unixY,
                                "rotation": 0,
                                "y": -20,
                                "x": 12,
                            },
                        },
                    ],

                    "tooltip": {
                        "shared": true,
                        formatter: function (tooltip) {
                            // Render custom tooltip
                            let seriesTooltip = chartTooltip[this.point.colorIndex] ?? [];
                            let pointTooltip = seriesTooltip[this.point.index] ?? null;

                            var s;
                            // Build the header
                            s = [tooltip.tooltipFooterHeaderFormatter(this.point)];

                            // build the values
                            s = s.concat(`${unixX}:  <b>${this.x}</b>`);
                            s = s.concat('<br/>');
                            s = s.concat(`${unixY}:  <b>${this.y}</b>`);
                            s = s.concat('<br/>');
                            s = s.concat(`Date:  <b>${pointTooltip ?? ''}</b>`);

                            // footer
                            s.push(tooltip.tooltipFooterHeaderFormatter(this.point, true));

                            return s;
                        }
                    },
                    "legend": {},
                    "series": chartSeries,
                    "plotOptions": {
                        "series": {
                            "animation": false,
                            "maxPointWidth": 100
                        },
                    }
                }}
            />
        )
    }
}

export default CustomComponent;