import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import helpers from "@ultis/helpers";
import { Redirect, withRouter } from "react-router-dom";
import { chartList } from "../../redux";
import * as CONSTANTS from "@ultis/constants";
import { getPlantData, getPlantDetail } from "@features/Plant/redux";
import { DATA_TYPE } from "@ultis/constants";
import { resetStore } from "@features/Common/redux";

const defaultRange = helpers.getTimeRangeByFilterTime(CONSTANTS.FILTER_LAST_7_DAYS)

class Container extends Component {
    props = this['props'];

    constructor(props) {
        super(props);
        this.state = {
            dataTypeDefault: DATA_TYPE.ACTUAL_PRODUCTION_AND_IRRADIATION,
            params: {
                from: defaultRange.from,
                to: defaultRange.to,
                groupBy: defaultRange.groupBy,
            },
        }
    }

    setDetailParams = params => {
        const { plantId, dataType } = this.props.match.params;
        let { to, from } = params;
        if (to !== null && from !== null) {
            const diffValue = to.diff(from);
            let groupBy;
            switch (true) {
                case diffValue <= 86400000:
                    groupBy = "hour"
                    break;
                case diffValue <= 2592000000:
                    groupBy = "date"
                    break;
                default:
                    groupBy = "month"
                    break;
            }

            this.setState({
                    ...this.state,
                    params: {
                        ...this.state.params,
                        from: from,
                        to: to,
                        groupBy: groupBy
                    }
                }, this.props.getPlantData({
                    ...this.state.params,
                    silent: false,
                    plantId: plantId,
                    dataType: dataType ?? this.state.dataTypeDefault,
                    from: from.format(CONSTANTS.DEFAULT_FORMAT_DATETIME),
                    to: to.format(CONSTANTS.DEFAULT_FORMAT_DATETIME),
                    groupBy: groupBy
                })
            );
        } else {
            this.setState({
                ...this.state,
                params: {
                    ...this.state.params,
                    from: from,
                    to: to,
                }
            });
        }
    }

    onChangeDataType = dataType => {
        this.props.history.push(dataType);
    }

    render = () => {
        const { isFound } = this.props.plant.detail;
        const { plantId, dataType } = this.props.match.params;

        if (!isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }

        return (
            <President
                {...this.state}
                {...this.props}
                parentId={plantId}
                currentDataType={dataType}
                dataType={dataType ?? this.state.dataTypeDefault}
                onChangeDataType={this.onChangeDataType}
                setDetailParams={this.setDetailParams}
            />
        )
    }

    componentDidMount = () => {
        this.props.chartList({ scope: "plant" })
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData())
    }

    componentDidUpdate = prevProps => {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if ((preParams.plantId !== currentParams.plantId)
            || (preParams.dataType !== currentParams.dataType)
        ) {
            this.fetchData();
        }
    }

    componentWillUnmount = () => {
        this.props.resetStore();
    }

    fetchData = () => {
        const { plantId, dataType } = this.props.match.params;
        const params = this.state.params;

        // Fetch data
        this.props.getPlantDetail(plantId);

        this.props.getPlantData({
            ...params,
            plantId: plantId,
            dataType: dataType ?? this.state.dataTypeDefault,
            from: params.from.format(CONSTANTS.DEFAULT_FORMAT_DATETIME),
            to: params.to.format(CONSTANTS.DEFAULT_FORMAT_DATETIME),
            silent: false,
        });
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        getPlantDetail: plantId => {
            dispatch(getPlantDetail(plantId));
        },
        getPlantData: params => {
            dispatch(getPlantData(params));
        },
        chartList: params => {
            dispatch(chartList(params));
        },
    };
}


const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        plant: state.plant,
        analysis: state.analysis,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));