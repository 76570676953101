import React, { Component } from 'react';
import { Col, Row, Skeleton } from "antd";
import * as Components from "@features/TechnicalMonitoring/components";

class President extends Component {
    render = () => {
        const { site, technicalMonitoring } = this.props;
        const { overview } = technicalMonitoring;
        const { detailSite } = site;
        const { data, loading } = detailSite;

        return (
            <div className="features feature-plant">
                <h1 className="page-title">
                    {loading ? <Skeleton.Input active={loading}/> : data.name}
                </h1>
                <Row gutter={{ xs: 8, sm: 12 }}>
                    <Col xs={24} xl={15}>
                        <Components.PlantList
                            overview={overview}
                            isDisplaySite={false}
                        />
                    </Col>
                    <Col xs={24} xl={9}>
                        <Components.GeolocationView
                            overview={overview}
                            onChangeResizeMap={this.props.onChangeResizeMap}
                        />
                    </Col>
                </Row>
                <div>
                    <Components.EventList/>
                </div>
            </div>
        )
    }
}

export default President;