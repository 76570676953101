// Initial state is the place you define all initial values for the Redux store of the feature.

const initialState = {
    detailSite: {
        loading: false,
        errors : {},
        data   : {},
        id     : null,
        isFound: true,
    },
};

export default initialState;
