const initialState = {
    list: {
        data: [],
        loading: false,
    },
    detail: {
        data: {},
        loading: false,
    },
    update: {
        data: {},
        loading: false,
    },
    downloadRequest: {
        loading: false,
        data: {
            url: null
        },
    }
};

export default initialState;