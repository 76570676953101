import React, { Component } from 'react';
import {
    WifiOutlined,
    BellFilled,
    ThunderboltFilled,
    LineOutlined,
    MenuOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Logo } from "../Logo";
import { AntButton } from "../AntButton";

class President extends Component {
    props = this['props'];

    render = () => {
        const { handleFullScreen, handleExitFullScreen, handleToggleSider } = this.props;

        const isFull = this.props.common.browserFullScreen.isActive;

        // Get logo url from props
        const { logoUrl } = this.props.auth.user.portfolio;

        return (
            <header className="page-header">
                <div className="page-header-content">
                    <div className="logo">
                        <Link to="/">
                            <Logo src={logoUrl}/>
                        </Link>
                    </div>
                    <div className="page-header-control">
                        <div className="status-control">
                            <div className="group-status">
                                <span className="group-label"><ThunderboltFilled/></span>
                                <span className="group-item highlight"><WifiOutlined/></span>
                                <span className="group-item highlight"><LineOutlined/></span>
                                <span className="group-item highlight"><LineOutlined/></span>
                            </div>
                            <div className="group-status">
                                <span className="group-label"><BellFilled/></span>
                                <span className="group-item highlight"><WifiOutlined/></span>
                                <span className="group-item highlight"><LineOutlined/></span>
                                <span className="group-item highlight"><LineOutlined/></span>
                            </div>
                        </div>
                        <div className="action-control">
                            <AntButton
                                className="btn-main-default"
                                icon={isFull ? <FullscreenExitOutlined/> : <FullscreenOutlined/>}
                                onClick={isFull ? handleExitFullScreen : handleFullScreen}
                            />
                            <AntButton
                                className="btn-main-default"
                                icon={<MenuOutlined/>}
                                onClick={handleToggleSider}
                            />
                        </div>

                    </div>
                </div>
            </header>
        )
    }
}

export default President;