import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row   : {
        flexDirection  : "row",
        fontWeight     : "bold",
        alignItems     : "center",
        color          : "red",
        height         : 18,
        backgroundColor: "aliceblue",
    },
    prefix: {
        width    : "70%",
        textAlign: "right",
    },
    total : {
        width    : "30%",
        textAlign: "right",
    },
});


const TableFooter = ({ totalConsumption }) => {
    return (
        <View style={styles.row}>
            <Text style={styles.prefix}></Text>
            <Text style={styles.total}>{totalConsumption}</Text>
        </View>
    )
};

export default TableFooter;