import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import {
    getPlantData,
    getPlantDetail,
} from "@features/Plant/redux";
import { resetStore } from "@features/Common/redux";
import * as CONSTANTS from "@ultis/constants";
import helpers from "@ultis/helpers";
import { fetchTableEvent } from "@features/Event/redux";

class Container extends Component {
    props = this['props'];

    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true,
            actualProduction: {
                dataType: CONSTANTS.DATA_TYPE.ACTUAL_PRODUCTION_AND_IRRADIATION,
                timeRange: CONSTANTS.FILTER_LAST_7_DAYS
            },
            stringMonitoring: {
                dataType: CONSTANTS.DATA_TYPE.STRING_MONITORING,
            },
            IVCurve: {
                dataType: CONSTANTS.DATA_TYPE.IV_CURVE,
                deviceNumber: null,
                stringNumber: null,
            },
            inverterMonitoring: {
                dataType: CONSTANTS.DATA_TYPE.INVERTER_MONITORING,
            },
            gaugesMonitoring: {
                dataType: CONSTANTS.DATA_TYPE.GAUGES_MONITORING,
            }
        }
    }

    onCollapsed = value => {
        this.setState({
            ...this.state,
            isCollapsed: value
        })
    }

    handleChangeTimeActual = value => {
        const { plantId } = this.props.match.params;
        const { actualProduction } = this.state;
     
        let range = helpers.getTimeRangeByFilterTime(value) 
 
        this.setState({
            ...this.state,
            actualProduction: {
                ...this.state.actualProduction,
                timeRange: value
            }
        }, this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: actualProduction.dataType,
        }))
    }

    handleChangeTimeActualBudget = value => {
        const { plantId } = this.props.match.params;

        let range = helpers.getTimeRangeByFilterTime(value);
        this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: CONSTANTS.DATA_TYPE.ACTUAL_PR_AND_BUDGET_PR,
        });
    }

    handleChangeTimeInverterPerformance = value => {
        const { plantId } = this.props.match.params;
        let range = helpers.getTimeRangeByFilterTime(value);
        this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: CONSTANTS.DATA_TYPE.INVERTER_PERFORMANCE,
        });
    }

    onChangeResizeChart = () => {
        console.log('onChangeResizeChart');
    }

    render = () => {
        const { isFound } = this.props.plant.detail;

        const { isCollapsed } = this.state;

        if (!isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }
        return (
            <President
                {...this.props}
                isCollapsed={isCollapsed}
                onCollapsed={this.onCollapsed}
                onChangeResizeChart={this.onChangeResizeChart}
                handleChangeTimeActual={this.handleChangeTimeActual}
                handleChangeTimeInverterPerformance={this.handleChangeTimeInverterPerformance}
                handleChangeTimeActualBudget={this.handleChangeTimeActualBudget}
            />)
    }

    componentDidMount = () => {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, data => {
            this.fetchData();
        });
    }

    componentDidUpdate = prevProps => {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if (preParams.plantId !== currentParams.plantId) {
            this.fetchData();
        }
    }

    componentWillUnmount = () => {
        this.props.resetStore()
    }

    fetchData = () => {
        const { plantId, siteId } = this.props.match.params;

        const { actualProduction, inverterMonitoring, gaugesMonitoring, stringMonitoring, IVCurve } = this.state;

        // Fetch data
        this.props.getPlantDetail(plantId);

        let range = helpers.getTimeRangeByFilterTime(actualProduction.timeRange);
        this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: actualProduction.dataType,
        });
        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: inverterMonitoring.dataType,
        });
        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: gaugesMonitoring.dataType,
        });

        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: stringMonitoring.dataType,
        });

        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: IVCurve.dataType,
        });

        this.props.fetchTableEvent({
            plantId: plantId,
            siteId: siteId,
        });
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },

        getPlantDetail: plantId => {
            dispatch(getPlantDetail(plantId));
        },

        getPlantData: params => {
            dispatch(getPlantData(params));
        },
        fetchTableEvent: params => {
            dispatch(fetchTableEvent(params));
        },
    };
}


const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        common: state.common,
        plant: state.plant,
        technicalMonitoring: state.technicalMonitoring,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));