import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { getPlantData, getPlantDetail, } from "@features/Plant/redux";
import { resetStore } from "@features/Common/redux";
import * as CONSTANTS from "@ultis/constants";
import helpers from "@ultis/helpers";
import { fetchTableEvent } from "@features/Event/redux";

class Container extends Component {
    props = this['props'];

    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    render = () => {
        const { isFound } = this.props.plant.detail;

        const { isCollapsed } = this.state;

        if (!isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }

        return (
            <President
                {...this.props}
                isCollapsed={isCollapsed}
            />)
    }

    componentDidMount = () => {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, (data) => {
            this.fetchData();
        });
    }

    componentDidUpdate = prevProps => {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if ((preParams.plantId !== currentParams.plantId)) {
            this.fetchData();
        }
    }

    componentWillUnmount = () => {
        this.props.resetStore();
    }

    fetchData = () => {
        const { plantId } = this.props.match.params;

        // Fetch data
        this.props.getPlantDetail(plantId);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },

        getPlantDetail: plantId => {
            dispatch(getPlantDetail(plantId));
        },
    };
}


const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        common: state.common,
        plant: state.plant,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
