import React, { Component } from 'react';
import { connect } from "react-redux";

import { AreaChartOutlined } from "@ant-design/icons";
import { AntCard, ToolboxControl, TagInverterStatus } from "@layouts";
import { Table } from "antd";

class CustomComponent extends Component {
    columns = () => {
        return [
            {
                ellipsis: true,
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (value) => <TagInverterStatus level={value}/>,
                align: "center",
                width: 80,
            },
            {
                ellipsis: true,
                title: 'Inverters',
                dataIndex: 'name',
                key: 'name',
                //sorter   : (a, b) => a.name - b.name,
            },
            {
                title: 'Production (kWh)',
                children: [
                    {
                        title: <b>Today</b>,
                        dataIndex: 'toDayProduction',
                        key: 'toDayProduction',
                        width: 110,
                        align: "right",
                    },
                    {
                        title: <b>Month to Date</b>,
                        dataIndex: 'monthToDateProduction',
                        key: 'monthToDateProduction',
                        width: 110,
                        align: "center",
                    },
                    {
                        title: <b>Year to Date</b>,
                        dataIndex: 'yearToDateProduction',
                        key: 'yearToDateProduction',
                        width: 110,
                        align: "left",
                    },
                ],
            },
            {
                title: "PR (%)",
                children: [
                    {
                        ellipsis: true,
                        title: <b>Today</b>,
                        dataIndex: "toDayPerformanceRatio",
                        key: "toDayPerformanceRatio",
                        width: 110,
                        align: "right",
                    },
                    {
                        ellipsis: true,
                        title: <b>Month to Date</b>,
                        dataIndex: "monthToDatePerformanceRatio",
                        key: "monthToDatePerformanceRatio",
                        width: 110,
                        align: "center",
                    },
                    {
                        ellipsis: true,
                        title: <b>Year to Date</b>,
                        dataIndex: "yearToDatePerformanceRatio",
                        key: "yearToDatePerformanceRatio",
                        width: 110,
                        align: "left",
                    },
                ],
            },
            {
                title: 'Specific Yield (kWh/kWp)',
                children: [
                    {
                        ellipsis: true,
                        title: <b>Today</b>,
                        dataIndex: 'toDaySpecificYield',
                        key: 'toDaySpecificYield',
                        width: 110,
                        align: "right",
                    },
                    {
                        ellipsis: true,
                        title: <b>Month to Date</b>,
                        dataIndex: 'monthToDateSpecificYield',
                        key: 'monthToDateSpecificYield',
                        width: 110,
                        align: "center",
                    },
                    {
                        ellipsis: true,
                        title: <b>Year to Date</b>,
                        dataIndex: 'yearToDateSpecificYield',
                        key: 'yearToDateSpecificYield',
                        width: 100,
                        align: "left",
                    },
                ],
            },
        ];
    }

    render = () => {
        const { common, meta } = this.props;
        const parentElementId = "plant-InverterMonitoring";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Data from inverter
        const {
            loading,
            total,
            lastTime,
            inverters
        } = meta.inverterPerformance;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> Inverter performance
                            <span> </span>
                            <i>- {total} Inverters</i>
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            updateAt={lastTime}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    <Table
                        columns={this.columns()}
                        dataSource={inverters}
                        pagination={false}
                        loading={loading}
                        rowKey={"id"}
                        scroll={{ x: 1100 }}
                    >
                    </Table>
                </div>
            </AntCard>
        )
    }

}

const mapStateToProps = state => {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);