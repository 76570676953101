import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { resetStore } from "@features/Common/redux";
import { getPortfolioDetail, updatePortfolio } from "@features/Configurations/PortfolioConfigurations/redux";

class Container extends Component {
    props = this['props'];
    /**
     * Finish submit form
     * @param data
     */
    onFinishForm = data => {
        // Form data
        let { id, logo, name } = data;

        // Create params
        let params = { "name": name ?? "" };

        if (logo) {
            let fileList = logo.fileList ?? [];
            logo = fileList.length > 0 ? fileList[0].originFileObj : null;

            // Add to params
            params["logo"] = logo;
        }

        this.props.updatePortfolio(id, params);
    }

    render = () => {
        return (
            <President
                {...this.props}
                onFinishForm={this.onFinishForm}
            />
        )
    }

    componentDidMount = () => {
        this.fetchData();
    }

    componentDidUpdate = prevProps => {
        //
    }

    componentWillUnmount = () => {
        this.props.resetStore();
    }

    fetchData = () => {
        const { user } = this.props.auth;
        const portfolio = user.portfolio ?? {};
        const id = portfolio.id ?? null;

        // Fetch data
        this.props.getPortfolioDetail(id);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        getPortfolioDetail: id => {
            dispatch(getPortfolioDetail(id));
        },
        updatePortfolio: (id, params) => {
            dispatch(updatePortfolio(id, params));
        },
    };
}

const mapStateToProps = state => {
    return {
        router: state.router,
        auth: state.auth,
        portfolioConfigs: state.portfolioConfigs,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);