import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { fetchPlants } from "../../redux";
import { resetStore } from "@features/Common/redux";
import { withRouter } from "react-router-dom";
import { fetchTableEvent } from "@features/Event/redux";
import * as CONSTANTS from "@ultis/constants";

class Container extends Component {

    props = this['props'];

    onChangeResizeMap = () => {
        this.props.fetchPlants();
    }

    render = () => {
        return (
            <President
                {...this.props}
                onChangeResizeMap={this.onChangeResizeMap}
            />
        )
    }

    componentDidMount = () => {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData())
    }

    componentWillUnmount = () => {
        this.props.resetStore()
    }

    fetchData = () => {
        // Get route params
        let { plantId, siteId } = this.props.match.params;
        plantId = plantId ?? null;
        siteId = siteId ?? null;

        this.props.fetchPlants();
        this.props.fetchTableEvent({
            siteId: siteId,
            plantId: plantId,
        });
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        fetchPlants: (siteId) => {
            dispatch(fetchPlants(siteId));
        },
        fetchTableEvent: (params) => {
            dispatch(fetchTableEvent(params));
        },
    };
}

const mapStateToProps = state => {
    return {
        socketIO: state.socketIO,
        common: state.common,
        technicalMonitoring: state.technicalMonitoring,
        event: state.event,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));