import { apiGet, apiPost } from "@common/crud";
import * as CONSTANTS from "./constants";
import { apiDelete } from "@common/crud/actions";

/**
 * Get rule
 * @param params
 * @returns
 */
export const getRules = (params = {}) => {
    let { plantId } = params;
    return dispatch => {
        dispatch(getRulesLoadingAction());
        dispatch(apiGet(`rules`, {
            "plantId": plantId ?? ""
        }, {}, getRulesAction));
    };
}

const getRulesAction = response => {
    return {
        type: CONSTANTS.GET_RULE_LIST,
        payload: response
    };
}

const getRulesLoadingAction = () => {
    return {
        type: CONSTANTS.GET_RULE_LIST_LOADING,
        payload: null
    };
}

/**
 * Get rule
 * @param id
 * @returns
 */
export const getRule = id => {
    return dispatch => {
        id = id ?? "";
        dispatch(getRuleLoadingAction());
        dispatch(apiGet(`rules/${id}`, {}, {}, getRuleAction));
    };
}

const getRuleAction = response => {
    return {
        type: CONSTANTS.GET_RULE_DETAIL,
        payload: response
    };
}

const getRuleLoadingAction = () => {
    return {
        type: CONSTANTS.GET_RULE_DETAIL_LOADING,
        payload: null
    };
}

/**
 * Create rule
 * @param params
 * @returns
 */
export const createRule = params => {
    return dispatch => {
        dispatch(createRuleLoadingAction());
        dispatch(apiPost(`rules/`, params, {}, createRuleAction));
    };
}

const createRuleAction = response => {
    return {
        type: CONSTANTS.CREATE_RULE,
        payload: response
    };
}

const createRuleLoadingAction = () => {
    return {
        type: CONSTANTS.CREATE_RULE_LOADING,
        payload: null
    };
}

/**
 * Get rule
 * @param id
 * @param params
 * @returns
 */
export const updateRule = (id, params) => {
    id = id ?? "";
    return dispatch => {
        dispatch(updateRuleLoadingAction());
        dispatch(apiPost(`rules/${id}`, params, {}, updateRuleAction));
    };
}

const updateRuleAction = response => {
    return {
        type: CONSTANTS.UPDATE_RULE,
        payload: response
    };
}

const updateRuleLoadingAction = () => {
    return {
        type: CONSTANTS.UPDATE_RULE_LOADING,
        payload: null
    };
}

/**
 * Get rule
 * @param id
 * @returns
 */
export const deleteRule = id => {
    id = id ?? "";
    return dispatch => {
        dispatch(deleteRuleLoadingAction());
        dispatch(apiDelete(`rules/${id}`, {}, deleteRuleAction));
    };
}

const deleteRuleAction = response => {
    return {
        type: CONSTANTS.DELETE_RULE,
        payload: response
    };
}

const deleteRuleLoadingAction = () => {
    return {
        type: CONSTANTS.DELETE_RULE_LOADING,
        payload: null
    };
}

/**
 * Get meta conditions
 * @param plantId
 * @returns
 */
export const getMetaConditions = (plantId = null) => {
    return dispatch => {
        dispatch(getMetaConditionsLoadingAction());
        dispatch(apiGet('rule-conditions', {
            "plantId": plantId ?? ""
        }, {}, getMetaConditionsAction));
    };
}

const getMetaConditionsAction = response => {
    return {
        type: CONSTANTS.GET_META_CONDITIONS,
        payload: response
    };
}

const getMetaConditionsLoadingAction = () => {
    return {
        type: CONSTANTS.GET_META_CONDITIONS_LOADING,
        payload: null
    };
}

/**
 * Clear form rule
 * @returns {{payload: null, type: string}}
 */
export const clearFormRule = () => {
    return {
        type: CONSTANTS.CLEAR_FORM_RULE,
        payload: null
    };
}